import axios, { AxiosResponse, Canceler } from 'axios';
import { BoothOpenResult } from '../components/account/Analytics/BoothOpens';
import { BoothRoomVisitorsResult } from '../components/account/Analytics/BoothRoomVisitorsTable';
import { VisitorsPerPage } from '../components/account/Analytics/EventVisitorsPerPage';
import { COMPONENTFILTER } from '../components/account/event/Homepage/Functions/QuickFilter';
import { GuestViewInterface } from '../components/account/guestmanagement/indexPage/GuestTable/GuestmanagementViews';
import { ProfileFieldObject } from '../components/account/guestmanagement/ProfileFields/ProfileFieldModal';
import { JoinStatus } from '../components/event/templates/VystemLight/oneonone/BigBooth/BigBoothEdit/BoothMeetingPartnerTab';
import {
  AdvancedFilter,
  AltSignup,
  ApiKey,
  Appointment,
  AttendeeMessage,
  AttendeeMessagePopulated,
  BillingDataInterface,
  BillingInfos,
  Booth,
  BoothAccessUserType,
  BoothContactShareCount,
  BoothLikesCount,
  BoothOrgUser,
  BroadcastInterface,
  Challenge,
  ChallengeSubmission,
  ContactShare,
  ControllingEntry,
  DataShare,
  DetailedTicketType,
  Email,
  EmailTemplate,
  EventInterface,
  EventPollAnswer,
  EventPollQuestion,
  EventRegistration,
  EventTemplate,
  Exhibitor,
  FavoriteAgendaEntry,
  FavoriteSlot,
  FavouriteBooth,
  FieldValuePair,
  LiveStream,
  MailReport,
  media,
  MeetingActivities,
  MeetingChatMessage,
  MeetingDetails,
  Meetingroom,
  MessageType,
  MessagingAttendee,
  MessagingConversation,
  MuxAsset,
  MuxPlaybackId,
  Network,
  NetworkProps,
  Organization,
  OrgMembership,
  Output,
  OutputPopulated,
  PageData,
  PageTemplate,
  PayoutInformation,
  PhotoBooth,
  PremiumServiceOrder,
  presentationSlotTypes,
  PresentationTicket,
  Privilege,
  PrivilegeRequestInterface,
  PrivilegeRequestPopulated,
  PrivilegeTypes,
  RegistrationFieldFilter,
  Role,
  RoleName,
  Simulcast,
  Slot,
  SupportedTicketPaymentCurrencies,
  SupportedTicketPaymentTypes,
  TeamMember,
  TicketDiscountCode,
  TicketOrder,
  TicketType,
  TrackingEventPopulated,
  User,
  UserBan,
  UserMatch,
} from '../interfaces/interfaces';
import { AgendaEntry, SpeakerEntry, UiComponent } from '../interfaces/pagebuilder';
import plainAxios from '../utils/plainAxios';
import { customAxios } from './backend';
import { RecursivePartial } from './helper';

export const avatarUpload = async (file: string | Blob) => {
  const formData = new FormData();
  formData.append('file0', file);
  const { data } = await customAxios.post('/file/userfile/avatar', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

export const getFilterCategories = async (eventid: string): Promise<{ data: AdvancedFilter[] }> => {
  const { data: filters } = await axios.get(`/filtercategory/getall/${eventid}`);
  return filters;
};

export const reorderFilters = async (filterId: string, sortIndex: number) => {
  const { data: filters } = await axios.post(`/filtercategory/reorder/${filterId}`, { sortIndex });
  return filters;
};

interface FilterValueProps {
  sortIndex: number;
  parentCategoryFilter: string;
  childCategoryFilter: string[];
  value: string;
}

export const updateFilterValue = async (valueId: string, data: Partial<FilterValueProps>) => {
  return axios.post(`/filtercategory/value/update/${valueId}`, data);
};

export const deleteFilterCategory = async (filterId: string) => {
  return axios.delete(`/filtercategory/delete/${filterId}`);
};

export const deleteFilterValue = async (valueId: string) => {
  return axios.delete(`/filtercategory/delete/${valueId}`);
};

export const createFilterCategoryValue = async (data: Partial<FilterValueProps>) => {
  return axios.post('/filtercategory/value/create', data);
};

export const deleteFilterValueCategory = async (filterId: string) => {
  return axios.delete(`/filtercategory/value/delete/${filterId}`);
};

interface FilterCategoryProps {
  event: string;
  isMainFilter: boolean;
  isHiddenFilter: boolean;
  name: string;
  sortIndex: number;
}

export const createFilterCategory = async (data: Partial<FilterCategoryProps>) => {
  return axios.post('/filtercategory/create', data);
};

export const updateFilterCategory = async (filterId: string, data: Partial<FilterCategoryProps>) => {
  return axios.post(`/filtercategory/update/${filterId}`, data);
};

export const signedUpload = async (url: string, file: any, onProgress?: (progress: number) => void) => {
  await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progressEvent: any) => {
      console.log('Upload progress event received ', progressEvent);
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
    withCredentials: false,
  });
};

// Document (file) services
// ToDo: modify it to allow multiple files
export const fileUpload = async (file: string | Blob, onProgress?: any) => {
  const formData = new FormData();
  formData.append('file0', file);
  const { data } = await customAxios.post('/file/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: any) => {
      console.log('Upload progress event received ', progressEvent);
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
  });
  return data;
};

export const generateUploadUrl = async (filename: string, type: string) => {
  const { data: data }: AxiosResponse<{ data: { urls: string[]; tempFileName: string } }> = await axios.post('/file/uploadurl/generate', { filename, type });
  return data.data;
};

export const completeUploadUrl = async (filename: string) => {
  const { data: data }: AxiosResponse<{ data: string[] }> = await axios.post('/file/uploadurl/complete', { filename });
  return data.data;
};

export const userFileUpload = async (file: string | Blob, eventId: string) => {
  const formData = new FormData();
  formData.append('file0', file);
  const { data } = await customAxios.post(`/file/userfile/upload/${eventId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return data;
};

export const publishFileToMux = async (file: string) => {
  const { data: asset }: AxiosResponse<{ data: MuxAsset }> = await axios.post('/file/publishmux', { fileId: file });
  return asset.data;
};

export const signupFileUpload = async (file: string | Blob, eventId: string, onProgress?: any) => {
  const formData = new FormData();
  formData.append('file0', file);
  const { data } = await customAxios.post(`/file/signup/upload/${eventId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
  });
  return data;
};

export const messagingFileUpload = async (file: string | Blob, onProgress?: any) => {
  const formData = new FormData();
  formData.append('file0', file);
  const { data } = await customAxios.post('/file/messaging/upload', formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: (progressEvent: any) => {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (onProgress) onProgress(percentCompleted);
    },
  });
  return data;
};

export const getUserDocuments = async (eventid: string, userId?: string) => {
  const { data } = await axios.post('file/userfile/getall', { eventid, userid: userId });
  return data;
};

export const deleteDocument = async (documentid: string) => {
  return axios.delete(`file/userfile/${documentid}`);
};

export interface GetEventBodyParams {
  pagename?: string;
  eventid?: string;
  slug?: string;
  filter?: string;
  filterBoothCategory?: string;
  filterCategoryValues?: string[];
  includeAllBooths?: boolean;
  searchPhrase?: string;
  filterCategoryValuesV2?: any;
  excludeBooths?: boolean;
}

export const getEvent = async (eventBodyParams: GetEventBodyParams): Promise<{ data: EventInterface; status: string }> => {
  const { data: event }: AxiosResponse<{ data: EventInterface; status: string }> = await axios.post('/event/get', eventBodyParams, {});
  return event;
};

export const deleteEvent = async (eventid: string) => {
  const res = await axios.post('/event/delete', { eventid });
  return res;
};

export const getEventBySlug = async (slug: string): Promise<{ data: EventInterface; status: string }> => {
  const { data: event }: AxiosResponse<{ data: EventInterface; status: string }> = await axios.get(`/event/byslug/${slug}`);
  return event;
};

export const getOwnEvents = async (): Promise<EventInterface[]> => {
  const { data }: AxiosResponse<any> = await axios.post('/event/getall/own', {
    limit: 500,
    population: [{ path: 'organization' }],
  });
  return data.data;
};

export const getUserAccessibleEvents = async (userId: string): Promise<EventInterface[]> => {
  const { data: events }: AxiosResponse = await axios.get(`/event/getall/accessible/${userId}`);
  return events.data.data;
};

export const getCustomDomains = async (): Promise<any[]> => {
  const { data: domains }: AxiosResponse<any> = await axios.get('/organization/customdomains');
  return domains.data;
};

export const getAllEvents = async (onlyFuture = false, limit = 10, population?: any, includePrivate = true, project?: any): Promise<any[]> => {
  const { data: events }: AxiosResponse<any> = await axios.post('/event/getall', {
    onlyFuture,
    limit,
    population,
    includePrivate,
    project,
  });
  return events.data;
};

interface GetAllBoothsParams {
  filter?: string;
  filterEvent?: string;
  filterOrganization?: string[] | string;
  limit?: number;
  offset?: string;
  orgid?: Organization;
  population?: Record<string, string>;
  sort?: any;
}

export interface GetAllBoothsReturnData {
  status: string;
  total: number;
  data: Booth[];
}

export const getBooths = async (boothBodyParams: GetAllBoothsParams): Promise<GetAllBoothsReturnData> => {
  const { data: booth }: AxiosResponse<GetAllBoothsReturnData> = await axios.post('/booth/getall', boothBodyParams);
  return booth;
};

export const getBoothsBySlug = async (slug: string): Promise<any> => {
  const { data: booths }: AxiosResponse<any> = await axios.get(`/booth/slug/${slug}`);
  return booths;
};
export const getBoothsByEventId = async (eventId: string): Promise<any> => {
  const { data: booths }: AxiosResponse<any> = await axios.get(`/booth/event/${eventId}`);
  return booths;
};

export interface GetBoothBodyParams {
  boothid?: string;
  eventid?: string;
}

export const getBooth = async (boothBodyParams: GetBoothBodyParams): Promise<Booth> => {
  const { data: booth }: AxiosResponse<{ data: Booth }> = await axios.post('/booth/get', boothBodyParams);
  return booth.data;
};

export const updateBooth = async (boothBodyParams: RecursivePartial<Booth>) => {
  const { data: booth }: AxiosResponse<{ data: Booth }> = await axios.post('/booth/update', boothBodyParams);
  return booth.data;
};

export const deleteBooth = async (boothid: string) => {
  const { data: booth }: AxiosResponse<Booth> = await axios.post('/booth/delete', { boothid });
  return booth;
};
export const deleteBooths = async (boothids: string[]) => {
  for await (const el of boothids) {
    await deleteBooth(el);
  }
  return;
};

export const trackHubspotUserProperty = async (updateObj: { property: string; value: string }) => {
  const { data: respo } = await axios.post('/user/marketingproperty', updateObj);
  return respo;
};

export const updateOrganization = async (orgObject: Partial<Organization>) => {
  const { data: organization } = await axios.post('/organization/update', orgObject);
  return organization;
};

export const getEventData = async (eventid: string) => {
  if (!eventid) return;
  const { data: event }: AxiosResponse<{ data: EventInterface }> = await axios.post('/event/get', { eventid });
  return event.data;
};

export const getPage = async (pageid: string) => {
  const { data: page }: AxiosResponse<{ data: PageData }> = await axios.get(`/event/pages/page/${pageid}`);
  return page;
};

export const deletePage = async (pageid: string) => {
  return axios.delete(`/event/pages/${pageid}`);
};

export const getAllTeamPages = async () => {
  const { data }: AxiosResponse<{ data: PageData[] }> = await axios.get(`/teampage/all`);
  return data.data;
};

export const getAllTeamPagesByPageId = async (pageId: string) => {
  const { data }: AxiosResponse<{ data: PageData[] }> = await axios.get(`/teampage/all/${pageId}`);
  return data.data;
};

export const getTeamPageByName = async (teamname: string, pagename: string) => {
  const { data }: AxiosResponse<{ data: PageData }> = await axios.get(`/teampage/name/${teamname}/${pagename}`);
  return data.data;
};

export const getTeamPageById = async (pageId: string) => {
  const { data }: AxiosResponse<{ data: PageData }> = await axios.get(`/teampage/${pageId}`);
  return data.data;
};
export const getAllTeamPagesByName = async (teamname: string, pagename: string) => {
  const { data }: AxiosResponse<{ data: PageData }> = await axios.get(`/teampage/all/name/${teamname}/${pagename}`);
  return data.data;
};

export const duplicateTeamPage = async (pageid: string) => {
  const { data: data } = await axios.post(`/teampage/duplicate/${pageid}`);
  return data;
};

export const createTeamPage = async () => {
  const { data }: AxiosResponse<{ data: PageData }> = await axios.post(`/teampage/create`);
  return data.data;
};

export const createOrUpdateTeamPage = async (pageId?: string, payload: Partial<PageData> = {}) => {
  const { data: page } = await axios.patch(`/teampage/${pageId ? pageId : ``}`, payload);
  return page;
};

export const updateTeamPageData = async (pagedata: PageData, triggerSocket: boolean) => {
  const { data }: AxiosResponse<any> = await axios.patch(`/teampage/${pagedata._id}`, { ...pagedata, triggerSocket });
  return data;
};

export const deleteTeamPage = async (pageId?: string) => {
  await axios.delete(`/teampage/${pageId}`);
};

export const getAllTeamEvents = async (teamName: string) => {
  const { data }: AxiosResponse<{ data: EventInterface[] }> = await axios.get(`/event/team/${teamName}`);
  return data.data;
};

export const createOrUpdatePage = async (pageId?: string, payload: Partial<PageData & { eventid: string }> = {}) => {
  const { data: page } = await axios.post(`/event/pages/${pageId ? pageId : ``}`, payload);
  return page;
};

export const getEventPages = async (eventid?: string, skipCaching = false) => {
  const { data }: AxiosResponse<{ data: PageData[] }> = await axios.get(`event/pages/${eventid}${skipCaching ? '?skipCaching=true' : ''}`);
  return data;
};

interface UpdateEventProps {
  eventid: string;
  lock: {
    datetime_locked?: Date | null;
    locked_by?: string;
  };
  name?: string;
  displayname?: string;
  private?: boolean;
  description?: string;
  eventauth?: {
    mustRegister?: boolean;
  };
  datetime_start?: Date;
  datetime_end?: Date;
  mate4eventid?: string;
  language?: 'en' | 'de';
  template: Partial<EventTemplate>;
  modules?: {
    [key: string]: {
      active?: boolean;
      settings?: {
        boothVideoUpload?: boolean;
        autoApprove?: boolean;
        syncWithGuestmgmt?: boolean;
      };
    };
  };
  settings?: {
    booking?: {
      active?: boolean;
    };
    showIntercomSupport?: boolean;
    intercomId?: string;
    predifinedRegistrationFeedbacks?: string[];
  };
  showOnboardingToastbar?: boolean;
  isPublished?: boolean;
  componentFilter: COMPONENTFILTER[];
}

export const updateEvent = async (params: Partial<UpdateEventProps>) => {
  const { data } = await axios.post(`/event/update`, params);
  return data;
};

export const getEventAmount = async () => {
  const { data } = await axios.get(`event/amount`);
  return data;
};

export const updatePageDataService = async (pagedata: PageData, triggerSocket: boolean) => {
  const { data }: AxiosResponse<any> = await axios.post(`/event/pages/${pagedata._id}`, { ...pagedata, triggerSocket });
  return data;
};

export const reorderPageService = async (pageId: string, pageIndex: number, isWeb: boolean) => {
  const { data }: AxiosResponse<any> = await axios.post(`/event/pages/reorder`, { pageId, pageIndex, isWeb });
  return data;
};

export const reorderComponentService = async (componentData: any) => {
  const { data }: AxiosResponse<any> = await axios.post(`/uicomponent/reorder`, componentData);
  return data.data;
};

export const toggleComponent = async (componentid: any) => {
  const { data }: AxiosResponse<any> = await axios.post(`/uicomponent/toggle`, { componentid });
  return data.data;
};

export const createComponent = async (pageid: string, componentData: any, isTeamPage = true) => {
  const { data }: AxiosResponse<any> = await axios.post(`/uicomponent`, { pageid, isTeamPage, ...componentData });
  return data.data;
};
export const updateComponentsService = async (components: UiComponent[], isTeamPage = true) => {
  const { data }: AxiosResponse<any> = await axios.post(`/uicomponent/update`, { components, isTeamPage });
  return data;
};

export const moveOutComponentService = async (componentid: string, pageid: string, isTeamPage = true) => {
  const { data }: AxiosResponse<any> = await axios.post(`/uicomponent/move`, { componentid, pageid, isTeamPage });
  return data;
};
export const copyComponentService = async (componentid: string, pageid: string, isTeamPage = false) => {
  const { data }: AxiosResponse<any> = await axios.post(`/uicomponent/copy`, { componentid, pageid, isTeamPage });
  return data;
};
export const deleteComponentService = async (component: UiComponent) => {
  await axios.delete(`/uicomponent/${component._id}`);
};

export const createMeeting = async (meetingObject: Partial<Meetingroom>) => {
  const { data: meeting } = await axios.post('/meeting/create', meetingObject);
  return meeting;
};

export const getDeletedMeetingrooms = async (eventid: string) => {
  const { data } = await axios.get(`/event/${eventid}/meetingrooms/deleted`);
  return data;
};

export const fetchFacebookDataService = async (accessToken: string) => {
  const { data } = await axios.post('/attendee/fetch/facebook', { accessToken });
  return data;
};

export const facebookRegisterService = async (accessToken: string) => {
  const { data } = await axios.post('/attendee/register/facebook', { accessToken });
  return data;
};

export const fetchLinkedinDataService = async (code: string, redirect: string) => {
  const { data } = await axios.get(`/attendee/fetch/linkedin/${code}/${encodeURIComponent(redirect)}`);
  return data;
};

export const linkedinRegisterService = async (code: string, redirect: string, eventid?: string, signup?: string) => {
  const { data } = await axios.post('/attendee/register/linkedin', { code, redirect, eventid, signup });
  return data;
};

export const fetchAttendeeMessagesService = async (eventid: string) => {
  const { data }: AxiosResponse<{ data: AttendeeMessage[] }> = await axios.get(`/messaging/${eventid}`);
  return data;
};

export const fetchAttendeeConversationsService = async (eventid: string) => {
  const { data }: AxiosResponse<{ data: MessagingConversation[] }> = await axios.get(`/messaging/conversations/${eventid}`);
  return data;
};

export const fetchOwnMessagingContactshares = async (eventid: string) => {
  const { data }: AxiosResponse<{ data: AttendeeMessagePopulated[] }> = await axios.get(`/messaging/contactshares/${eventid}`);
  return data;
};

export const getTotalContactSharesInBooth = async (boothId: string) => {
  const { data }: AxiosResponse<{ data: DataShare[] }> = await axios.get(`/reporting/booth/${boothId}/contactshare`);
  return data.data;
};

export const getCountContactSharesOfBooth = async (boothId: String) => {
  const { data: totalContactShares } = await axios.get(`/reporting/booth/totalcontactshare/${boothId}`);
  return totalContactShares.data;
};
export const getContactSharesPerBooth = async (eventId: String) => {
  const { data }: AxiosResponse<{ data: BoothContactShareCount[] }> = await axios.get(`/reporting/event/getContactSharesPerBooth/${eventId}`);
  return data.data;
};
export const getUsersMeetingJoinStatus = async (boothid: String) => {
  const { data }: AxiosResponse<{ data: JoinStatus[] }> = await axios.get(`/reporting/joinstatus/${boothid}`);
  return data.data;
};
interface VcardDetailsResponse {
  user: User;
  registrationFields: { value: string; field: string }[];
}

export const getVcardMessageDetails = async (messageId: string) => {
  const { data }: AxiosResponse<{ data: VcardDetailsResponse }> = await axios.get(`/messaging/vcard/${messageId}`);
  return data.data;
};

export const fetchMessagingAttendeesService = async (
  eventId: string,
  startIndex = 0,
  search = '',
  onlineFilter = false,
  registrationFieldsFilter: RegistrationFieldFilter[] = [],
  showHosts = false,
  sortByName?: 'ascending' | 'descending'
) => {
  const { data } = await axios.post(`/messaging/attendees`, {
    eventId,
    startIndex,
    search,
    onlineFilter,
    registrationFieldsFilter,
    showHosts,
    sortByName,
  });
  return data;
};

export const fetchMessagingBoothOpensService = async (eventid: string) => {
  const { data }: AxiosResponse<{ data: { boothid: string; attendee: MessagingAttendee }[] }> = await axios.get(`/messaging/booth/opens/${eventid}`);
  return data;
};

export const seeAttendeeMessageService = async (eventId: string, attendeeId: string) => {
  const { data }: AxiosResponse<{ data: AttendeeMessage[] }> = await axios.post(`/messaging/see`, {
    eventId,
    attendeeId,
  });
  return data;
};

export const sendAttendeeMessageService = async (event: string, recepient: string, message: string = '', files: string[] = [], messageType?: MessageType) => {
  const { data }: AxiosResponse<{ data: AttendeeMessage }> = await axios.post(`/messaging/send`, {
    event,
    recepient,
    message,
    files,
    messageType,
  });
  return data;
};

export const updateMessagingSettings = async (eventId: string, updateObj: { messagingGhostMode?: boolean; messagingAllowNotifications?: boolean }) => {
  await axios.post(`/messaging/settings`, { eventId, updateObj });
};

export interface GetMeetingBodyParams {
  onlyvisible?: boolean;
  onlycanjoin?: boolean;
  onlylive?: boolean;
  filterBooth?: string;
  filterOwnHost?: boolean;
  filterEvent?: string;
  sort?: string;
  hasSlot?: boolean;
  hasName?: boolean;
  meetingtype?: string;
  limit?: number;
  offset?: number;
  isStudioForPresentationSlot?: boolean;
}

export const getMeetings = async (getMeetingBodyParams: GetMeetingBodyParams) => {
  const { data: meeting }: AxiosResponse<{ status: string; data: Meetingroom[] }> = await axios.post('/meeting/getall', getMeetingBodyParams);
  return meeting;
};

export const getMeeting = async (meetingroomid: string) => {
  const { data } = await axios.get(`/meeting/${meetingroomid}`);
  return data.data;
};

export const updateMeeting = async (meetingObject: Partial<Meetingroom>) => {
  const { data: meeting } = await axios.post('/meeting/update', meetingObject);
  return meeting;
};

export const updateAllMeetings = async (meetingroomIds: string[], meetingObject: Partial<Meetingroom>) => {
  const { data: meeting } = await axios.patch('/meeting/all', { meetingroomIds, ...meetingObject });
  return meeting;
};

export const deleteMeeting = async (meetingroomid: string) => {
  const { data: meeting } = await axios.post('/meeting/delete', { meetingroomid });
  return meeting;
};

export const restoreMeeting = async (meetingroomid: string) => {
  const { data: meeting } = await axios.post('/meeting/restore', { meetingroomid });
  return meeting;
};

export const infosPresentationMeeting = async (meetingroomid: string) => {
  const { data: users } = await axios.get(`/reporting/meetinguserminutes/${meetingroomid}`);
  return users;
};

interface CreateSlotBodyParams {
  datetime_start: string;
  datetime_end: string;
  meetingroom: string;
  notifyOwners: boolean;
  sortIndex: number;
}

export const getSlots = async (filterEvent?: string) => {
  const { data: slots }: AxiosResponse<{ status: string; data: Slot[] }> = await axios.get(`/slot/getall/${filterEvent}`);
  return slots;
};

export const getSlotsForSlug = async (eventSlug: string) => {
  const { data: slots }: AxiosResponse<{ status: string; data: Slot[] }> = await axios.get(`/slot/getall/slug/${eventSlug}`);
  return slots;
};

export const getFavoriteSlots = async (eventid?: string) => {
  const { data: favoriteslots }: AxiosResponse<{ status: string; data: FavoriteSlot[] }> = await axios.post('/slot/get/ownfavourite', { eventid });
  return favoriteslots;
};

export const createSlot = async (createSlotBodyParams: CreateSlotBodyParams) => {
  const { data: slots }: AxiosResponse<{ status: string; data: string }> = await axios.post('/slot/create', createSlotBodyParams);
  return slots;
};

export const deleteSlot = async (slotid: string) => {
  const { data: slot } = await axios.post('/slot/delete', { slotid });
  return slot;
};

export const notifySlot = async (slotid: string) => {
  const { data: slot } = await axios.post('/slot/create/send', { slotid });
  return slot;
};

export const followSlot = async (slotid: string, eventid: string) => {
  const data = await axios.post('/slot/follow', { slotid, eventid });
  return data.data;
};

export const unfollowSlot = async (slotid: string, eventid: string) => {
  const data = await axios.post('/slot/unfollow', { slotid, eventid });
  return data.data;
};

export const getMatchingCount = async (): Promise<number> => {
  const { data: data }: AxiosResponse<{ data: { description: string; count: number } }> = await axios.post('/meeting/matching/count');
  return data.data.count;
};

export interface GetMatchingStatusBody {
  params: {
    event: string;
  };
}

export const getMatchingStatus = async (matchingBody: GetMatchingStatusBody) => {
  const { data: status }: AxiosResponse<{ status: string; data: boolean }> = await axios.get('/meeting/matching/status', matchingBody);
  return status;
};

export interface UpdateMatchingStatusBody {
  activatematching: boolean;
  event: string;
}

export const updateMatchingStatus = async (matchingBody: UpdateMatchingStatusBody) => {
  const { data: status }: AxiosResponse<{ status: string; data: boolean }> = await axios.post('/meeting/matching/status', matchingBody);
  return status;
};

export const sendMatchingResponse = async (matchingResponseBody: {
  meetingroomid: string;
  response: 'accept' | 'reject' | 'ignore';
  message?: string | null;
  eventId?: string;
}) => {
  const { data: data }: AxiosResponse<{ status: string; data: string }> = await axios.post('/meeting/matching/response', matchingResponseBody);
  return data;
};

export interface UpdateUserProps {
  name?: string;
  emailOptIn?: boolean;
  userid?: string;
  avatar?: string | null;
  updateUser?: string;
  lastSeenVersionInfo?: string;
  lastSeenAd?: number;
}

export const updateUser = async (updateUserProps: UpdateUserProps) => {
  const { data }: AxiosResponse<{ status: string }> = await axios.post('/user/update', updateUserProps);
  return data;
};

export const getAllUsers = async (
  limit: number,
  filterOrganization?: string,
  population?: { path: string }[],
  filterBooth?: string
): Promise<{ data: TeamMember[]; status: string; total: number }> => {
  const { data } = await axios.post('/user/getall', { limit, filterOrganization, population, filterBooth });
  return data;
};

export const getUser = async (userid?: string): Promise<{ data: User; status: string }> => {
  const { data } = await axios.post('/user/get', { userid });
  return data;
};

interface UserCreateProps {
  name: string;
  email: string;
  role: string;
  exhibitor: string;
}

export const createUser = async (props: UserCreateProps): Promise<{ data: User; status: string }> => {
  const { data } = await axios.post('/user/create', props);
  return data;
};

export const deleteUser = async (userid: string): Promise<void> => {
  const { data } = await axios.post('/user/delete', { userid });
  return data;
};

export const getAuthData = async () => {
  const {
    data,
  }: AxiosResponse<{
    status: string;
    data: { user: User; role: Role; updateLocalStorage: boolean; access_token: string; registrationId?: string };
  }> = await axios.post('/auth/get');
  return data;
};

export const getAlgoliaSearchApiKey = async () => {
  const { data }: AxiosResponse<{ data: { searchApiKey: string } }> = await axios.get('auth/algolia/key');
  return data.data.searchApiKey;
};

export const getMailsByEvent = async (eventId: string, offset = 0, limit = 25, search?: string) => {
  const { data }: AxiosResponse<{ status: string; data: Email[]; total: number }> = await axios.get(`/mail/event/${eventId}`, {
    params: { offset, limit, search },
  });
  return data;
};

export const getMailReport = async (eventid: string) => {
  const { data }: AxiosResponse<{ status: string; data: MailReport[] }> = await axios.post('/mail/report', { eventid });
  return data;
};

interface sendQuestionProps {
  pageid: string;
  question: string;
}

// _____________ QA ___________________

export const fetchQAQuestionsService = async (pageid: string) => {
  const { data } = await axios.get(`/qa/${pageid}?includeAll=true&combined=true`);
  return data;
};

export const controlQAQuestionService = async (questionid: string, control: string) => {
  const { data } = await axios.post('/qa/question/control', { questionid, control });
  return data;
};

export const deleteQAQuestionService = async (questionid: string) => {
  const { data } = await axios.delete(`/qa/question/${questionid}`, {});
  return data;
};

export const sendQAQuestionService = async (props: sendQuestionProps) => {
  const { data } = await axios.post('/qa/question', props);
  return data;
};

export const combineQAQuestionsService = async (question: string, pageid: string, questions: string[]) => {
  const { data } = await axios.post('qa/question/combine', {
    question,
    pageid,
    questions,
  });
  return data;
};

export const answerQAQuestionService = async (questionid: string, answer: string) => {
  const { data } = await axios.post('/qa/answer', { questionid, answer });
  return data;
};

export const deleteQAAnswerService = async (answerid: string) => {
  const { data } = await axios.delete(`/qa/answer/${answerid}`);
  return data;
};

export const fetchQAPreviewQuestionService = async (pageid: string) => {
  const { data } = await axios.get(`/qa/preview/${pageid}`);
  return data.data;
};

export const assignQACategoryService = async (categories: string[], question: string) => {
  const { data } = await axios.post('qa/category/assign', { categories, question });
  return data;
};

export const createQACategoryService = async (category: string, eventid: string) => {
  const { data } = await axios.post('qa/category/create', { category, eventid });
  return data;
};

export const fetchQACategoriesService = async (eventid: string) => {
  const { data } = await axios.post(`/qa/category`, { eventid });
  return data;
};

export const deleteQACategoryService = async (categoryid: string) => {
  const { data } = await axios.delete(`qa/category/${categoryid}`);
  return data;
};
// _____________ QA ___________________

// network

export const createNetwork = async (eventid: string, props: NetworkProps) => {
  const { data } = await axios.post('/networking', { ...props, eventid });
  return data.data;
};

export const getNetworks = async (eventid: string): Promise<Network[]> => {
  const { data } = await axios.get(`/networking/${eventid}`);
  return data.data;
};

export const updateNetwork = async (id: string, props: Partial<NetworkProps>) => {
  const { data } = await axios.post(`/networking/${id}`, props);
  return data;
};

export const deleteNetwork = async (id: string) => {
  const { data } = await axios.delete(`/networking/${id}`);
  return data;
};

export const addMeetingAssignment = async (meetingroom: string, email: string) => {
  const { data } = await axios.post(`/meeting/assignment`, { email, meetingroom });
  return data;
};

export const deleteMeetingAssignment = async (assignmentId: string) => {
  const { data } = await axios.delete(`/meeting/assignment/${assignmentId}`);
  return data;
};

export const getMeetingsByEvent = async (eventId: string, offset = 0, limit = 25, sort?: any) => {
  const { data }: AxiosResponse<{ status: string; data: Meetingroom[]; total: number }> = await axios.get(`/meeting/event/${eventId}`, {
    params: { offset, limit, sort },
  });
  return data;
};

export const getFrontendConfig = async () => {
  const res = await axios.post('/config/get', { path: 'frontend' });
  return res.data.data;
};

//

export const getAllAttendeesByOrg = async (organizationId: string, searchValue?: string, page?: number) => {
  const { data: data } = await axios.post(`/attendee/byorg/${organizationId}`, { searchValue, page });
  return data;
};

//TODO: Add type here
export const getAllAttendees = async (
  eventId: string,
  offset?: number,
  limit?: number,
  query?: { search?: string; email?: string; signup?: string | null; tickettype?: string },
  includecanceled?: boolean,
  showConfirmedOnly?: boolean,
  expectedfields?: FieldValuePair[]
) => {
  const { data: data } = await axios.post(`/attendee/getall`, {
    eventid: eventId,
    offset,
    limit,
    query,
    includecanceled,
    showConfirmedOnly,
    expectedfields,
  });

  return data;
};

// This needs to be refactored to use AbortController after updating to axios v0.22.0 https://axios-http.com/docs/cancellation
const CancelToken = axios.CancelToken;
let cancel: Canceler | undefined;

export const getAttendeeTotal = async (
  eventId: string,
  query?: { search?: string; email?: string; signup?: string | null; tickettype?: string },
  includecanceled?: boolean,
  showConfirmedOnly?: boolean,
  expectedfields?: FieldValuePair[]
) => {
  if (cancel) cancel('new-reqest');

  const { data: data } = await axios.post(
    `/attendee/total`,
    {
      eventid: eventId,
      query,
      includecanceled,
      showConfirmedOnly,
      expectedfields,
    },
    {
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      }),
    }
  );
  return data;
};

export const getRegistration = async (registrationId: string, accessToken?: string) => {
  const accessConfig = { headers: { Authorization: accessToken || axios.defaults.headers.common.authorization } };

  const { data } = await plainAxios.get(`/attendee/registration/${registrationId}`, accessConfig);
  return data;
};

export const getRegistrationTicketPreview = async (eventId: string) => {
  const { data } = await axios.get(`/attendee/ticket/preview/${eventId}`);
  return data;
};

export const getPublicRegistration = async (userId: string, eventId: string) => {
  const { data }: AxiosResponse<{ data: EventRegistration }> = await axios.get(`/attendee/registration/public/${userId}/${eventId}`);
  return data;
};

export const getRegistrationsByUser = async () => {
  const { data }: AxiosResponse<{ data: (Omit<EventRegistration, 'event'> & { event: EventInterface })[] }> = await axios.get(`/attendee/registrations/`);
  return data.data;
};

export const cancelRegistration = async (registrationId: string) => {
  const { data } = await axios.post(`/attendee/registration/cancel/${registrationId}`);
  return data;
};

export const updateRegistration = async (registrationId: string, obj: any, withValidationNotice = true, forceNewMail = false) => {
  const { data } = await axios.post(`/attendee/registration/${registrationId}`, { ...obj, withValidationNotice, forceNewMail });
  return data;
};

export const getAttendeeRegistration = async (userid: string, eventid: string) => {
  const { data } = await axios.get(`/attendee/info/${userid}/${eventid}`);
  return data;
};

export const updateProfileStatus = async (updateObj: User['profileStatus']) => {
  const { data } = await axios.post(`/user/updatestatus`, { updateObj });
  return data;
};

export const fetchRecordings = async (eventId: string) => {
  const { data } = await axios.get(`/meeting/recording/list/${eventId}`);
  return data;
};

export const getRecordingsOfRoom = async (meetingId: string) => {
  const { data: data } = await axios.get(`/meeting/recording/room/${meetingId}`);
  return data.data;
};

export const createRecording = async (meetingroom: string, provider: string, datetime_scheduled_start?: Date, datetime_scheduled_end?: Date) => {
  const { data } = await axios.post(`/meeting/recording/`, {
    meetingroom,
    provider,
    datetime_scheduled_start,
    datetime_scheduled_end,
  });
  return data;
};

export const stopRecording = async (meetingId: string) => {
  const { data } = await axios.post(`/meeting/recording/meetingid/${meetingId}`);
  return data;
};

export const getRegistrationFields = async (eventid: string) => {
  const { data } = await axios.get(`/guestmanagement/field/${eventid}`);
  return data;
};

export const createOrUpdateRegistrationField = async (props: ProfileFieldObject) => {
  await axios.post(`guestmanagement/field${props.registrationFieldId ? '/' + props.registrationFieldId : ''}`, { ...props });
};

export const createGuestmanagementView = async (props: Omit<GuestViewInterface, '_id'>) => {
  const { data } = await axios.post(`guestmanagement/view`, { ...props });
  return data;
};

export const getGuestmanagementViews = async (eventId: string) => {
  return await axios.get(`guestmanagement/view/${eventId}`);
};

export const deleteGuestmanagementView = async (id: string) => {
  return await axios.delete(`guestmanagement/view/${id}`);
};

export const duplicateRegistrationFields = async (itemid: string, eventid?: string) => {
  const { data } = await axios.post(`/guestmanagement/field/${itemid}/duplicate`, { eventid });
  return data;
};

export const getLimitedRegistrationFieldsData = async (eventid: string) => {
  const { data } = await axios.get(`/guestmanagement/limitedfields/${eventid}`);
  return data;
};

export const seeIncompleteProfileModalService = async (registrationId: string) => {
  await axios.post(`/guestmanagement/seeincompleteprofile/${registrationId}`);
};

export const deleteAllAttendees = async (eventid: string) => {
  const { data } = await axios.delete(`/event/${eventid}/attendee`);
  return data;
};

export const createPresentation = async (
  booth?: string,
  name?: string,
  title?: string,
  presentationSlotType?: presentationSlotTypes,
  presentationMaxSeats?: number,
  presentationRequireSignup?: boolean,
  eventid?: string
) => {
  let meeting;

  try {
    meeting = createMeeting({
      boothid: booth,
      meetingtype: 'presentation',
      contactperson: name,
      name: title,
      presentationSlotType,
      presentationMaxSeats,
      presentationRequireSignup,
      event: eventid,
    });
  } catch {
    return;
  }
  return meeting;
};

// Appointment services

interface createAppointmentProps {
  meetingroom: string;
  datetime_start: Date;
  datetime_end: Date;
}

export const createMeetingAppointment = async (props: createAppointmentProps) => {
  return axios.post('/meeting/appointment/create', props);
};

export const getMeetingAppointments = async (meetingroomid: string) => {
  const { data: appointments }: AxiosResponse<{ data: Appointment[] }> = await axios.post('/meeting/appointment/getall', { meetingroomid });
  return appointments.data;
};

export const getOwnAppointments = async (eventid?: string) => {
  const { data: appointments }: AxiosResponse<{ data: Appointment[] }> = await axios.get(`/meeting/appointment/get/own/${eventid}`);
  return appointments.data;
};

export const bookAppointment = async (appointmentid?: string) => {
  const { data: appointment }: AxiosResponse<{ data: Appointment[] }> = await axios.post('/meeting/appointment/book', {
    appointment: appointmentid,
  });
  return appointment.data;
};

export const getOwnShares = async (eventid?: string) => {
  const { data: shares }: AxiosResponse<{ data: ContactShare[] }> = await axios.get(`/reporting/get/ownshares/${eventid}`);
  return shares.data;
};

export const unbookAppointment = async (appointmentid: string) => {
  const { data: success } = await axios.post('/meeting/appointment/unbook', {
    appointment: appointmentid,
  });

  return success;
};

export const deleteAppointment = async (appointmentId: string) => {
  return await axios.delete(`/meeting/appointment/delete/${appointmentId}`);
};

export const deleteAppointments = async (appointmentids: string[]) => {
  return await axios.delete(`/meeting/appointment/delete/all`, { data: { ids: appointmentids } });
};

// live stream services
export const getLiveStreams = async (eventid?: string) => {
  const { data: livestreams }: AxiosResponse<{ data: LiveStream[] }> = await axios.get(`/livestreams/getall/${eventid}`);
  return livestreams.data;
};

export const getLiveStream = async (streamId?: string) => {
  const { data: livestream }: AxiosResponse<{ data: LiveStream }> = await axios.get(`/livestreams/${streamId}`);
  return livestream.data;
};

interface createLivestreamProps {
  name: string;
  isRecording: boolean;
  event: string;
  simulcast?: [
    {
      passthrough: string;
      url: string;
      streamKey: string;
    }
  ];
}

export const createLivestream = async (props: createLivestreamProps) => {
  const { data: livestream } = await axios.post('/livestreams', props);
  return livestream.data;
};

interface updateLivestreamProps {
  liveStreamId: string;
  record?: boolean;
  name?: string;
  thumbnail?: string | null;
  simulcast?: {
    passthrough: string;
    url: string;
    streamKey: string;
  }[];
}

export const updateLivestream = async (id: string, props: updateLivestreamProps) => {
  const { data: livestream } = await axios.post('/livestreams/' + id, props);
  return livestream.data;
};

export const updateSimulcast = async (id: string, simulcast: Simulcast[]) => {
  const { data: livestream } = await axios.patch(`/livestreams/simulcast/${id}`, {
    id,
    simulcast: simulcast ?? [],
  });

  return livestream.data;
};

interface getLivestreamRecordsProps {
  liveStreamId: string;
  event: string;
  id: string;
}

export const deleteLivestream = async (id: string) => {
  const { data: livestream } = await axios.delete(`/livestreams/` + id);
  return livestream;
};

export const fetchStreamImpressions = async (id: string) => {
  const { data: data } = await axios.get(`/livestreams/impressions/${id}`);
  return data;
};
// challenges services
export const getChallenges = async (eventid?: string) => {
  const { data: challenges }: AxiosResponse<{ data: Challenge[] }> = await axios.get(`/challenge/${eventid}`);
  return challenges.data;
};

export interface ChallengeProps {
  eventid?: string;
  tasks: any;
  _id?: string;
  name?: string;
  description?: string;
  isActive?: boolean;
  isSequential?: boolean;
  event?: string;
  winner?: User;
  submissions?: ChallengeSubmission[];
}
export const createChallenge = async (props: ChallengeProps) => {
  return await axios.post(`challenge/`, props);
};

export const updateChallenge = async (challengeId: string, updateObj: ChallengeProps) => {
  return axios.post(`challenge/${challengeId}`, { ...updateObj });
};

export const deleteChallenge = async (challengeId: string) => {
  return axios.delete(`challenge/${challengeId}`);
};
// eventbrite services
export const getEventbriteOrgs = async () => {
  const { data: orgs } = await axios.post('/integration/eventbrite/organization/getall');
  return orgs.data;
};

export const getEventbriteOrgEvents = async (eventbrite_organization: string) => {
  const { data: events } = await axios.post('/integration/eventbrite/event/getall', { eventbrite_organization });
  return events.data;
};

interface importEventbriteUsersProps {
  eventbrite_event: string;
  eventid: string;
  sendTicketEmails?: boolean;
  importWithId?: boolean;
}

export const importEventbriteUsers = async (props: importEventbriteUsersProps) => {
  const { data: events } = await axios.post('/integration/eventbrite/attendee/import', props);
  return events.data;
};

export const syncEventbriteUsers = async (props: importEventbriteUsersProps) => {
  const { data: events } = await axios.post('/integration/eventbrite/attendee/sync', props);
  return events.data;
};

export const deactivateEventbriteSync = async (eventId: string) => {
  const { data: events } = await axios.delete(`/integration/eventbrite/attendee/sync/${eventId}`);
  return events.data;
};

export const getAllApiKeys = async () => {
  const { data: apiKeys }: AxiosResponse<{ data: ApiKey[] }> = await axios.get(`/integration/apiKey/all`);
  return apiKeys.data;
};

export const createApiKey = async (name: string) => {
  const { data: apiKey }: AxiosResponse<{ data: ApiKey }> = await axios.post(`/integration/apiKey`, { name });
  return apiKey.data;
};

export const deleteApiKey = async (id: string) => {
  return await axios.delete(`/integration/apiKey/${id}`);
};

//photobooth services

export const getPhotobooths = async (eventid?: string) => {
  const { data: photobooths }: AxiosResponse<{ data: PhotoBooth[] }> = await axios.get(`/photobooth/getall/${eventid}`);
  return photobooths.data;
};

interface createPhotoboothProps {
  file: string;
  event: string;
  user?: string;
}

export const createPhotobooth = async (props: createPhotoboothProps) => {
  const { data: photobooth } = await axios.post('/photobooth', props);
  return photobooth.data;
};

export const deletePhotobooth = async (id: string) => {
  return await axios.delete('/photobooth/' + id);
};

export const updatePhotobooth = async (id: string, props: any) => {
  const { data: photobooth } = await axios.post('/photobooth/' + id, props);
  return photobooth.data;
};

// Tracking services

export const trackVCardsDownloads = async (meetingroomId: string, userId?: string, boothid?: string) => {
  const { data: eventregistration } = await axios.post('/tracking/track', {
    event: 'VCARD_DOWNLOAD',
    userid: userId,
    eventid: null,
    meetingroomid: meetingroomId,
    args: { boothid },
  });
  return eventregistration.data;
};

export const trackHandlungsempfehlungenStudieDownloads = async (fileId: string, userId?: string, boothid?: string) => {
  const { data: downloads } = await axios.post('/tracking/track', {
    event: 'HANDLUNGSEMPFEHLUNGEN_STUDIE_DOWNLOAD',
    userid: userId,
    eventid: null,
    meetingroomid: null,
    args: { boothid, fileId },
  });
  return downloads.data;
};

export const trackEvent = async (event: string, args: any, eventid?: string) => {
  const data = await axios
    .post('/tracking/track', { event, args, eventid })
    .then(({}) => {})
    .catch();
  return data;
};

// Analytics services
export const getRegistrationsCount = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: eventregistration } = await axios.get(`/reporting/event/registeredusercount/${eventid}${_appendTimeFrame(start, end)}`);
  return eventregistration.data;
};

export const getRegistrationsPerDayCount = async (eventid?: string, end?: Date, start?: Date): Promise<{ count: number; date: string }[]> => {
  const { data: registrations } = await axios.get(`/reporting/event/eventregistrationtimeline/${eventid}${_appendTimeFrame(start, end)}`);
  return registrations.data;
};

export const getEventMeetingMinutes = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: eventMeetingMinutes } = await axios.get(`/reporting/event/meetingminutes/${eventid}${_appendTimeFrame(start, end)}`);
  return eventMeetingMinutes.data;
};

export const getEventCurrentMeetingsUsers = async (eventid?: string) => {
  const { data: eventCurrentMeetingsUsers } = await axios.get(`/reporting/event/currentmeetingsusers/${eventid}`);
  return eventCurrentMeetingsUsers.data;
};

export const getEventTotalParticipantsNetworkingRooms = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: eventTotalParticipantsNetworkingRooms } = await axios.get(`/reporting/event/networkingroomusercount/${eventid}${_appendTimeFrame(start, end)}`);
  return eventTotalParticipantsNetworkingRooms.data;
};

export const getEventTotalContactShare = async (eventid?: string) => {
  const { data: totalContactShares } = await axios.get(`/reporting/event/totalcontactshare/${eventid}`);
  return totalContactShares.data;
};

export const getEventAverageCallLength = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: eventAverageCallLength } = await axios.get(`/reporting/event/averagecalllength/${eventid}${_appendTimeFrame(start, end)}`);
  return eventAverageCallLength.data;
};

export const getEventTotalParticipantsBoothsOpened = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: eventTotalParticipantsBoothsOpened } = await axios.get(`/reporting/event/boothsopeneduserscount/${eventid}${_appendTimeFrame(start, end)}`);
  return eventTotalParticipantsBoothsOpened.data;
};

export const getEventAppUsageCount = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: appUsage } = await axios.get(`/reporting/event/appusagecount/${eventid}${_appendTimeFrame(start, end)}`);
  return appUsage.data;
};

export const getLockedUsers = async (eventid?: string) => {
  const { data: locked } = await axios.get(`/reporting/event/lockedusers/${eventid}`);
  return locked.data;
};

function _appendTimeFrame(start?: Date, end?: Date) {
  return `${start && end ? `?end=${new Date(end)?.toISOString()}&start=${new Date(start)?.toISOString()}` : ''}`;
}

// Hybrid Analytics
export const getHybridEventUsersStatus = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: usersStatus } = await axios.get(`/hybrid/statistics/currentcheckinstatus/${eventid}${_appendTimeFrame(start, end)}`);
  return usersStatus.data;
};

export const getHybridEventStatusesPerSlot = async (eventid?: string) => {
  const { data: statusesCount } = await axios.get(`/hybrid/statistics/currentcheckinstatus/${eventid}/bylimited`);
  return statusesCount.data;
};

export const getHybridEventStatusesPerForm = async (eventid?: string) => {
  const { data: statusesCount } = await axios.get(`/hybrid/statistics/currentcheckinstatus/${eventid}/byform`);
  return statusesCount.data;
};
export const getEventAverageTime = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: time } = await axios.post(`/hybrid/statistics/averagetime/${eventid}${_appendTimeFrame(start, end)}`);
  return time.data;
};
export const getEventVisitsTime = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: time } = await axios.get(`/hybrid/statistics/visitstime/${eventid}${_appendTimeFrame(start, end)}`);
  return time.data;
};
export const getEventCheckInPerMinuteRate = async (eventid?: string, end?: Date, start?: Date) => {
  const { data: rate } = await axios.get(`/hybrid/statistics/checkinsperminute/${eventid}${_appendTimeFrame(start, end)}`);
  return rate.data;
};

export const getEventTotalVisitorsPerPage = async (eventid?: string, end?: Date, start?: Date): Promise<VisitorsPerPage[]> => {
  const { data: eventTotalVisitorsPerPage } = await axios.get(`/reporting/event/visitorsperpage/${eventid}${_appendTimeFrame(start, end)}`);
  return eventTotalVisitorsPerPage.data;
};

export const getBoothRoomVisitorsByRoomType = async (eventid?: string, end?: Date, start?: Date): Promise<BoothRoomVisitorsResult[]> => {
  const { data: visitorsPerRoomType } = await axios.get(`reporting/event/participantsperboothperroomtype/${eventid}${_appendTimeFrame(start, end)}`);
  return visitorsPerRoomType.data;
};

export const getLikesPerBooth = async (eventid?: string, end?: Date, start?: Date): Promise<Booth[]> => {
  const { data: visitorsPerRoomType } = await axios.get(`reporting/event/likesperbooth/${eventid}${_appendTimeFrame(start, end)}`);
  return visitorsPerRoomType.data;
};

export const getMessagesPerBooth = async (eventId?: string) => {
  const { data: messagesPerBooth } = await axios.get(`reporting/event/booth/messages/${eventId}`);
  return messagesPerBooth.data;
};

export const getBoothOpensByBooth = async (eventid?: string, end?: Date, start?: Date): Promise<BoothOpenResult[]> => {
  const { data: opens } = await axios.get(`reporting/event/boothopens/${eventid}${_appendTimeFrame(start, end)}`);
  return opens.data;
};

export const getActivity = async (eventid?: string) => {
  const { data: activity } = await axios.get(`/hybrid/check/activity/event/${eventid}`);
  return activity.data;
};

export const checkInOut = async (
  mode: 'in' | 'out',
  registrationId?: string,
  provider?: string,
  providerToken?: string,
  selectedGuests?: string[],
  useGroupTicket = false
) => {
  const { data } = await axios.post(`/hybrid/check/${mode}/`, { registrationId, provider, providertoken: providerToken, selectedGuests, useGroupTicket });
  return data;
};

interface TotalCheckInProps {
  userId: string;
  username: string;
  type: string;
  checkedIn: number;
  checkedOut: number;
}

interface TotalGuestValidationProps {
  email: string;
  name: string;
  count: number;
  _id: string;
}

export const getTotalCheckInPerUser = async (eventid?: string, end?: Date, start?: Date): Promise<TotalCheckInProps[]> => {
  const { data: checkin } = await axios.get(`/hybrid/statistics/totalcheckinperuser/${eventid}${_appendTimeFrame(start, end)}`);
  return checkin.data;
};

export const getTotalGuestValidation = async (eventid?: string, end?: Date, start?: Date): Promise<TotalGuestValidationProps[]> => {
  const { data: checkin } = await axios.get(`/hybrid/statistics/guestvalidation/${eventid}${_appendTimeFrame(start, end)}`);
  return checkin.data;
};

export const getUsersCheckInsTimeLine = async (eventid?: string, date?: Date) => {
  const { data: checkIns } = await axios.get(`/hybrid/statistics/event/checkinstimeline/${eventid}?date=${date}`);
  return checkIns.data;
};
// Email template services
export const getEventEmailTemplates = async (eventid: string) => {
  const { data }: AxiosResponse<{ status: string; data: EmailTemplate[] }> = await axios.get('emailtemplate/event/' + eventid);
  return data.data;
};

export const createEmailTemplate = async (props: any) => {
  const { data: template }: AxiosResponse<{ emailTemplate: EmailTemplate }> = await axios.post('/emailtemplate', props);
  return template.emailTemplate;
};

export const deleteEmailTemplate = async (templateId: any) => {
  return await axios.delete('/emailtemplate/' + templateId);
};

export const duplicateEmailTemplate = async (templateId: string, eventId: string) => {
  return await axios.post('/emailtemplate/' + templateId + '/duplicate', { eventid: eventId });
};

export const updateEmailTemplate = async (props: any, templateId: string) => {
  const { data: template } = await axios.post('/emailtemplate/' + templateId, props);
  return template;
};
export const previewEmailBaseTemplate = async (baseId: string, eventid: string) => {
  const { data } = await axios.get('/emailtemplate/preview/' + baseId + '?language=en&eventid=' + eventid);
  return data;
};

interface sendTestEmailProps {
  baseTemplate?: string;
  event: string;
  email: string;
  language?: string;
  templateId?: string;
}

export const sendTestEmailTemplate = async (props: sendTestEmailProps) => {
  const { data } = await axios.post('/emailtemplate/preview/testmail', props);
  return data;
};

interface resendTicketProps {
  email: string;
  eventid?: string;
}

export const resendTicket = async (props: resendTicketProps) => {
  return axios.post('/attendee/register/ticketcode/resend', props);
};

//
export const getUsersList = async (eventid?: string) => {
  const { data: userslist } = await axios.get(`/reporting/event/allregisteredusers/${eventid}`);
  return userslist.data;
};
// Booth stats Services

export const getBoothReporting = async (boothid?: string) => {
  const { data: boothReporting } = await axios.get(`/reporting/booth/${boothid}/getall`);
  return boothReporting.data;
};

export const getBestPresentations = async (eventid?: string) => {
  const { data: bestPresentations } = await axios.post('/reporting/event/bestpresentations', { filterEvent: eventid });
  return bestPresentations.data;
};
export const getBoothChatmessages = async (boothid?: string) => {
  const { data: boothchatMessages } = await axios.get(`/reporting/chatmessagescount/${boothid}`);
  return boothchatMessages.data;
};

export const getAttendeeChatsOfBooths = async (eventId?: string) => {
  const { data } = await axios.get(`/reporting/totalattendeechatsofbooths/${eventId}`);
  return data.data;
};

export const getBoothImpressions = async (boothid?: string) => {
  const { data: boothImpressions } = await axios.get(`/reporting/impressionscount/${boothid}`);
  return boothImpressions.data;
};

export const getMeetingMinutes = async (boothid?: string) => {
  const { data: boothMeetingMinutes }: AxiosResponse<{ status: string; data: { count: number; meetingtype: string }[] }> = await axios.get(
    `/reporting/meetingminutescount/${boothid}`
  );
  return boothMeetingMinutes.data;
};

export const getBoothPdfOpens = async (boothid?: string) => {
  const { data: boothPdfOpens } = await axios.get(`/reporting/pdfopens/${boothid}`);
  return boothPdfOpens.data;
};

export const getPdfOpensOfAllBooths = async (eventId?: string) => {
  const { data } = await axios.get(`/reporting/pdfopensofbooths/${eventId}`);
  return data.data;
};

export const getBoothVideoViews = async (boothid?: string) => {
  const { data: boothVideoViews } = await axios.get(`/reporting/booth/videoviews/${boothid}`);
  return boothVideoViews.data;
};

export const getBoothVCardsDownloads = async (boothid?: string) => {
  const { data: boothVCardsDownloads } = await axios.get(`/reporting/booth/vcarddownlods/${boothid}`);
  return boothVCardsDownloads.data;
};

// Attendee service
// this is currently used in guestmgnt to invite a user
export const registerAttendee = async (obj: object) => {
  const { data } = await axios.post(`/attendee/register`, obj);
  return data;
};

export const resendAttendee = async (regid: string) => {
  const { data } = await axios.post(`/attendee/register/resend`, { registrationid: regid });
  return data;
};
export const resendAllAttendees = async (eventId: string) => {
  const { data } = await axios.post(`/attendee/register/resend/all`, { eventId });
  return data.data;
};

// Guest Validation
export const getNextRegistrationService = async (
  eventid: string,
  query: any,
  expectedfields: any,
  expectedfieldsComparator: string,
  lockAttendee: boolean,
  excludedRegistration?: string
) => {
  const { data } = await axios.post(`/attendee/validation/next`, {
    eventid,
    query,
    expectedfields,
    expectedfieldsComparator,
    lockAttendee,
    excludedRegistration,
  });
  return data;
};

export const statusAllAttendees = async (eventid: string, status: string) => {
  return axios.post(`attendee/status/pending`, { eventid, status });
};

export const sendTestEmail = async (obj: object) => {
  return await axios.post(`/attendee/testemail`, obj);
};
export const getDraftBroadcast = async (eventid: string) => {
  return await axios.get(`event/director/draftbroadcast/${eventid}`);
};
export const sendBroadcast = async (
  eventid: string,
  message: string,
  type: string,
  isDraft = false,
  datetime_expiry: Date,
  sendPushnotificationToUsers = false
) => {
  return await axios.post('/event/director/broadcast', {
    eventid,
    message,
    type,
    isDraft,
    datetime_expiry: datetime_expiry || undefined,
    sendPushnotificationToUsers,
  });
};

export const deleteBroadcast = async (broadcastid: string) => {
  return await axios.post(`/event/director/broadcast/delete/${broadcastid}`);
};
export const getAllBroadcast = async (eventid: string) => {
  const { data }: AxiosResponse<{ data: BroadcastInterface[] }> = await axios.get(`event/director/broadcast/${eventid}`);
  return data;
};
export const getPermanentBroadcast = async (eventid: string) => {
  const { data }: AxiosResponse<{ data: BroadcastInterface }> = await axios.get(`event/director/broadcast/permanent/${eventid}`);
  return data.data;
};
export const stopBroadcast = async (broadcastid: string, eventid: string) => {
  return await axios.post(`/event/director/broadcast/stopbroadcasting`, {
    broadcastid,
    eventid,
  });
};
export const startArchive = async (studioId: string) => {
  return await axios.post(`/studio/${studioId}/archive/start`);
};

export const createStudio = async (eventId: string, values: any) => {
  const { data }: AxiosResponse<{ data: Meetingroom }> = await axios.post('/studio', {
    event: eventId,
    ...values,
  });

  return data.data;
};
export const updateStudio = async (studioId: string, body: any) => {
  await axios.post(`studio/${studioId}`, body);
};

export const deleteStudio = async (studioId: string) => {
  await axios.delete(`/studio/${studioId}`);
};

export const getStudios = async (eventId: string) => {
  const { data: studios }: AxiosResponse<{ data: Meetingroom[] }> = await axios.get(`/studio/event/${eventId}`);
  return studios.data;
};

export const getAllMedia = async (eventid: string, searchQuery?: string) => {
  const { data }: AxiosResponse<{ data: media[] }> = await axios.get(`/media/getall/${eventid}`, { params: { searchQuery: searchQuery } });
  return data.data;
};

export const getOrgMedia = async (orgid: string, searchQuery?: string) => {
  const { data }: AxiosResponse<{ data: media[] }> = await axios.get(`/media/orgmedia/${orgid}`, { params: { searchQuery: searchQuery } });
  return data.data;
};

interface MediaProps {
  mediaid?: string;
  name: string;
  path: string;
  type?: string;
  description: string;
  number: string;
  author: string;
  authorposition: string;
  eventid?: string;
  file?: string;
  thumbnail?: string | null;
}

export const createMedia = async (props: MediaProps) => {
  const { data: media }: AxiosResponse<{ data: media }> = await axios.post('/media/create', props);
  return media.data;
};

export const updateMedia = async (props: MediaProps) => {
  const { data: media }: AxiosResponse<{ data: media }> = await axios.post('/media/update', props);
  return media.data;
};

export const getMediaPlaybackIds = async (mediaId: string) => {
  const { data: playbackIds }: AxiosResponse<{ data: MuxPlaybackId[] }> = await axios.get(`/media/${mediaId}/playbackIds`);
  return playbackIds.data;
};

export const requestEmailSend = (email: string) => {
  return axios.post('auth/request', { email });
};

export const sendOnboard = (boothid: string) => {
  return axios.post('booth/onboard/send', { boothid });
};

export const addBooth = (props: { [key: string]: string | number | boolean | undefined }) => {
  return axios.post('booth/onboard', props);
};
// feedback
export const getAllFeedbacks = async (eventid?: string) => {
  const { data: feedbacks } = await axios.get(`/feedback/${eventid}/getall`);
  return feedbacks.data;
};

export const createFeedback = async (eventid: string, name: string) => {
  const { data: feedback } = await axios.post(`/feedback/create`, { eventid, name });
  return feedback.data;
};

export const deleteFeedback = async (feedbackid: string) => {
  await axios.delete(`/feedback/${feedbackid}`);
};

//Attendeeinformation
export const getAttendeeInformationForEvent = async (eventId: string) => {
  const { data: data } = await axios.get(`userinformation/attendee/${eventId}`);
  return data;
};

export const createAttendeeInformation = async (eventId: string, payload?: any) => {
  const { data: data } = await axios.post(`userinformation/attendee`, { ...payload, event: eventId });
  return data.data;
};

export const updateAttendeeInformation = async (attendeeInformationId: string, payload: any) => {
  const { data: data } = await axios.post(`userinformation/attendee/${attendeeInformationId}`, { ...payload });
  return data;
};

export const sendAttendeeInformation = async (attendeeInformationId: string, onlyParticipatedUsers = false) => {
  const { data: data } = await axios.post(`/userinformation/attendee/${attendeeInformationId}/send`, {
    onlyParticipatedUsers,
  });
  return data;
};

export const getAttendeeInfoSendEstimate = async (attendeeInformationId: string, onlyParticipatedUsers = false) => {
  const { data: data } = await axios.get(`/userinformation/attendee/${attendeeInformationId}/send/estimate`);
  return data;
};

export const sendTestAttendeeInformation = async (attendeeInformationId: string, email: string) => {
  const { data: data } = await axios.post(`/userinformation/attendee/${attendeeInformationId}/test`, {
    email,
  });
  return data;
};

export const deleteAttendeeInformation = async (attendeeInformationId: string) => {
  const { data: data } = await axios.delete(`/userinformation/attendee/${attendeeInformationId}`);
  return data;
};

export const duplicateAttendeeInformation = async (attendeeInformationId: string, eventId?: string) => {
  const { data: data } = await axios.post(`/userinformation/attendee/${attendeeInformationId}/duplicate`, { eventid: eventId });
  return data;
};

export const getAttendeeInformation = async (attendeeInformationId: string) => {
  const { data: data } = await axios.get(`userinformation/infos/attendee/${attendeeInformationId}`);
  return data.data;
};
export const getAllAltSignups = async (eventid: string) => {
  const { data } = await axios.get(`/guestmanagement/altsignup/event/${eventid}`);
  return data;
};

export const getAltSignup = async (altSignupId: string) => {
  const { data } = await axios.get(`/guestmanagement/altsignup/${altSignupId}`);
  return data;
};

export const createAltSignup = async (AltSignup: AltSignup) => {
  const { data } = await axios.post(`/guestmanagement/altsignup`, { createObj: AltSignup });
  return data;
};

export const updateAltSignupService = async (AltSignup: AltSignup) => {
  const { data } = await axios.post(`/guestmanagement/altsignup/update`, { updateObj: AltSignup });
  return data;
};

export const deleteAltSignup = async (altSignupId: string, newAltSignupId?: string) => {
  const { data } = await axios.post(`/guestmanagement/altsignup/${altSignupId}`, { newAltSignupId: newAltSignupId });
  return data;
};
export const getInfectionTrackingCheckAndReport = async (eventid: string, email: string) => {
  const { data: data } = await axios.get(`hybrid/infections/event/infectiontrack?eventId=${eventid}&email=${email}`);
  return data.data;
};
//Access Rules
export const getAccessRulesForEvent = async (eventId: string) => {
  const { data: data } = await axios.get(`hybrid/access/event/${eventId}`);
  return data;
};

export const createAccessRule = async (eventId: string, payload: any) => {
  const { data: data } = await axios.post(`hybrid/access`, { ...payload, event: eventId });
  return data;
};

export const updateAccessRule = async (ruleId: string, payload: any) => {
  const { data: data } = await axios.post(`hybrid/access/${ruleId}`, { ...payload });
  return data;
};

export const deleteAccessRule = async (ruleId: string) => {
  const { data: data } = await axios.delete(`hybrid/access/${ruleId}`);
  return data;
};

export const duplicateAccessRule = async (ruleId: string) => {
  const { data: data } = await axios.post(`hybrid/access/duplicate/${ruleId}`);
  return data;
};

//Email confirm
export const generateConfirmCode = async (email: string, event: string, name: string) => {
  const { data: data } = await axios.post(`/user/emailconfirm/generate`, {
    email,
    event,
    name,
  });
  return data;
};

export const checkConfirmCode = async (code: string, email?: string, userId?: string, currentUserId?: string) => {
  const { data: data } = await axios.post(`/user/emailconfirm/check`, {
    email,
    code,
    userId,
    currentUserId,
  });
  return data;
};

export const resendConfirmCode = async (email: string, event: string) => {
  const { data: data } = await axios.post(`/user/emailconfirm/resend`, {
    email,
    event,
  });
  return data;
};

export const getConfirmationStatus = async (email: string, previousEmail: string, eventId: string) => {
  const { data: data } = await axios.get(`/user/emailconfirm/status?email=${email}&previousemail=${previousEmail}&event=${eventId}`);
  return data;
};

export const updateUsersRole = async (userId: string, organizationId: string, role: RoleName) => {
  const { data: data } = await axios.patch(`/user/updaterole`, { userId, organizationId, role });
  return data;
};

export const lockRegistration = async (registrationId: string) => {
  const { data: data } = await axios.post(`/attendee/registration/lock/${registrationId}`);
  return data;
};

export const unlockRegistration = async (registrationId: string) => {
  const { data: data } = await axios.post(`/attendee/registration/unlock/${registrationId}`);
  return data;
};
export const resetPassword = async (password: string, resettoken: string) => {
  const { data: data } = await axios.post(`/auth/reset`, { password, resettoken });
  return data;
};
export const checkResetToken = async (resettoken: string) => {
  const { data: data } = await axios.post(`/auth/reset/checktoken`, { resettoken });
  return data;
};
export const duplicatePage = async (pageid: string, eventid: string) => {
  const { data: data } = await axios.post(`/event/pages/${pageid}/duplicate`, { eventid });
  return data;
};

export const getOwnPrivilege = async (eventid: string) => {
  const { data: data } = await axios.get(`/userprivilege/getown/${eventid}`);
  return data;
};

export const getAllUsersPrivileges = async (eventid: string) => {
  const { data: data }: AxiosResponse<{ data: Privilege[] }> = await axios.get(`/userprivilege/getall/${eventid}`);
  return data?.data;
};

// privilege request apis
export const createPrivilegeRequest = async (event: string, privilege: keyof typeof PrivilegeTypes) => {
  await axios.post(`/privilegerequest/`, { event, privilege });
};

export const getEventPrivilegeRequests = async (event: string) => {
  const { data: data }: AxiosResponse<{ data: PrivilegeRequestPopulated[] }> = await axios.get(`/privilegerequest/all/${event}`);
  return data.data;
};

export const getEventPrivilegeRequestsByOrg = async (org: string) => {
  const { data: data }: AxiosResponse<{ data: PrivilegeRequestPopulated[] }> = await axios.get(`/privilegerequest/all/org/${org}`);
  return data.data;
};

export const getOwnPrivilegeRequests = async (event: string) => {
  const { data: data }: AxiosResponse<{ data: PrivilegeRequestInterface[] }> = await axios.get(`/privilegerequest/own/${event}`);
  return data.data;
};

export const processPrivilegeRequest = async (event: string, requestid: string, isGranted: boolean) => {
  await axios.post(`/privilegerequest/process`, { event, requestid, isGranted });
};
export const getOrgUsers = async (orgid: string) => {
  const { data } = await axios.get(`/organization/users/${orgid}`);
  return data?.data;
};

export const getOrgMemberships = async () => {
  const { data } = await axios.get(`/user/orgmemberships`);
  return data?.data;
};

export const createOrganization = async (org: { name: string }) => {
  const { data } = await axios.post(`/organization/usercreate`, { ...org });
  return data?.data;
};

export const switchOrganization = async (orgid: string) => {
  const { data } = await axios.post(`/auth/switchorg`, { orgid });
  return data?.data;
};

export const leaveOrganization = async (orgid: string) => {
  await axios.delete(`/organization/membership/${orgid}`);
};

export const updatePrivilege = async (user: string, event?: string, updateObj?: any, booth?: string, type = 'event', allAccess?: boolean) => {
  const data = await axios.post(`/userprivilege/update`, {
    user,
    event: !event ? undefined : event,
    booth: !booth ? undefined : booth,
    type,
    allAccess,
    ...updateObj,
  });
  return data.data.data;
};

export const deletePrivilege = async (user: string, booth: string) => {
  const data = await axios.delete(`/userprivilege/booth/${booth}/${user}`);
  return data.data;
};

export const getQueuePositions = async (eventId?: string) => {
  const { data: data } = await axios.get(`/meeting/queue/event/${eventId}`);
  return data;
};

export const leaveQueue = async (queuePositionId: string) => {
  const { data } = await axios.post('/meeting/queue/leave', { queuePositionId });
  return data;
};

export const queueResponse = async (queueId: string, response: 'accept' | 'reject') => {
  const { data } = await axios.post('/meeting/queue/response', { queueId, response });
  return data;
};
export const getAllQueueForBooth = async (boothid: string) => {
  const { data } = await axios.get(`/meeting/queue/booth/${boothid}`);
  return data;
};

export const checkEventAuth = async (
  eventId: string
): Promise<{
  data: { status: boolean; registration: EventRegistration; role: 'attendee' | 'exhibitor'; language: 'de' | 'en' | 'fr' | null };
  status: string;
}> => {
  const { data: data } = await axios.get(`/attendee/checkeventauth/${eventId}`);
  return data;
};

export const sendLike = async (emote: string, pageId?: string | string[], meetingId?: string, eventId?: string) => {
  const { data } = await axios.post('/like/send', {
    pageId,
    meetingId,
    eventId,
    emote,
  });

  return data?.data;
};

// notification services
export const getNotifications = async (eventid: string) => {
  const { data: notifictions } = await axios.get(`/notification/${eventid}/getown`);
  return notifictions.data;
};

export const markNotificationAsSeen = async (eventid: string) => {
  const { data: notifictions } = await axios.post(`/notification/markseen`, { eventid });
  return notifictions.data?.data;
};

// Type stage Chat services
export const sendStageMessages = async (pageid: string, message: string, eventid?: string, replyTo?: string) => {
  const { data } = await axios.post(`/meeting/chat/send`, {
    pageid,
    message,
    eventid,
    replyTo,
  });
  return data.data;
};

export const deleteMessage = async (messageid: string) => {
  return await axios.delete(`/meeting/chat/message/${messageid}`);
};

export const clearStageMessages = async (pageid: string) => {
  return await axios.post(`/meeting/chat/clear`, { pageid });
};

export const sendMeetingMessage = async (message: string, eventid: string, meetingid: string, replyTo?: string) => {
  const { data } = await axios.post('/meeting/chat/send', { message, eventid, meetingid, replyTo });

  return data.data;
};

export const getChatMessages = async (meetingId: string, minuteOffset?: number, offset?: number): Promise<MeetingChatMessage[]> => {
  const { data: chatmessages } = await axios.post('meeting/chat/getall', {
    meetingroomid: meetingId,
    limit: 100,
    minuteOffset,
    offset,
  });

  return chatmessages.data;
};

export const reportMessage = async ({
  messageid,
  event,
  reason,
  userid,
}: {
  messageid?: string;
  event?: string;
  reason?: string;
  userid?: string;
}): Promise<any> => {
  const { data }: AxiosResponse = await axios.post('userreport/reportmessage', {
    userid,
    messageid,
    event,
    reason,
  });

  return data;
};

export const reportMessagingAttendee = async ({
  messageid,
  event,
  reason,
  userid,
}: {
  messageid?: string;
  event?: string;
  reason?: string;
  userid?: string;
}): Promise<any> => {
  const { data }: AxiosResponse = await axios.post('userreport/messaging/report', {
    userid,
    messageid,
    event,
    reason,
  });

  return data;
};

export const getUserBans = async (eventid?: string): Promise<UserBan[]> => {
  const { data }: AxiosResponse = await axios.get(`userreport/userbans/${eventid}`);
  return data.data;
};

export const createTokenForAttendee = async (vonagesessionid: string, role: string, username: string, meetingid: string): Promise<any> => {
  const { data } = await axios.post('meeting/control/createtemporarytoken', {
    vonagesessionid,
    username,
    role,
    meetingid,
  });

  return data.data;
};

export const addTemporarySpeaker = async (meetingid: string, userid: string) => {
  const { data } = await axios.post('meeting/control/addtemporaryspeaker', {
    meetingid,
    userid,
  });

  return data;
};

export const cancelTemporarySpeaker = async (meetingid: string, userid: string) => {
  const { data } = await axios.post('meeting/control/canceltemporaryspeaker', {
    meetingid,
    userid,
  });

  return data;
};

export const sendKnockRequest = async (meetingid: string, userid: string): Promise<boolean> => {
  await axios.post('meeting/knock/knock', {
    meetingroom: meetingid,
    userid,
  });

  return true;
};

export const sendKnockAnwer = async (accept: boolean, meetingroom: string, userid: string): Promise<boolean> => {
  await axios.post('meeting/knock/reply', {
    type: accept ? 'grant' : 'decline',
    meetingroom,
    userid,
  });

  return true;
};

export const joinMeeting = async (
  meetingroomid: string,
  username?: string,
  meetingPassword?: string,
  jointoken?: string,
  viewerMode?: boolean,
  appointment?: string
) => {
  const { data } = await axios.post(
    'meeting/join',
    {
      meetingroomid,
      meetingPassword: meetingPassword?.toUpperCase(),
      username,
      jointoken,
      viewerMode,
      appointment,
    },
    {
      headers: {
        'x-vystem-vsx': 'True',
      },
    }
  );

  return data;
};

export const requestContactShare = async (meetingroomid: string) => {
  const { data: successful }: AxiosResponse = await axios.post('meeting/attendee/sharedata/request', {
    meetingroomid,
  });

  return successful;
};

export const shareDataInMeeting = async (
  meetingroomid: string,
  email: string,
  name: string,
  message: string,
  userFiles = null,
  boothid?: string
): Promise<any> => {
  const { data: successful }: AxiosResponse = await axios.post('meeting/attendee/sharedata', {
    meetingroomid,
    boothid: boothid,
    email,
    name,
    message: message,
    files: userFiles,
  });

  return successful;
};

export const shareDataInBooth = async (boothid: string, email: string, name: string, message: string, files: any) => {
  const { data } = await axios.post('booth/sharedata', {
    boothid,
    email,
    name,
    message,
    files,
  });

  return data;
};

export const shareContact = async () => {};

export const joinStudio = async (studioId: string, studioPassword: string, username: string) => {
  const { data } = await axios.post('studio/join', {
    studioId,
    studioPassword,
    username,
  });
  return data;
};

// Event poll
export const createEventPoll = async (eventId: string, question: string, isActive?: boolean) => {
  const { data: data } = await axios.post(`/poll/question`, { event: eventId, content: question, isActive: isActive });
  return data.data;
};

export const createMeetingPoll = async (meeting: string, question: string, isActive?: boolean) => {
  const { data: data } = await axios.post(`/poll/meetingquestion`, {
    meeting: meeting,
    content: question,
    isActive: isActive,
  });
  return data.data;
};

export const getPollsPerEvent = async (eventId: string) => {
  const { data: data } = await axios.get(`/poll/getall/${eventId}`);
  return data.data;
};

export const getPollsPerMeeting = async (meeting: string) => {
  const { data: data } = await axios.get(`/poll/allmeetingquestions/${meeting}`);
  return data.data;
};

export const getPollPreviewForEvent = async (eventId: string): Promise<EventPollQuestion | null> => {
  const { data: data } = await axios.get(`/poll/preview/${eventId}`);
  return data.data;
};

export const deleteEventPoll = async (questionId: string) => {
  const { data: data } = await axios.delete(`/poll/question/${questionId}`);
  return data;
};

export const clearEventPoll = async (questionId: string) => {
  const { data: data } = await axios.delete(`/poll/question/clear/${questionId}`);
  return data;
};

export const updateEventPoll = async (
  questionId: string,
  content?: string,
  isActive?: boolean,
  isShared?: boolean,
  showOnPreviewMonitor?: boolean,
  outputShowWholeAnswer?: boolean,
  showPercentage?: boolean,
  allowMultiSelect?: boolean
) => {
  const { data: data } = await axios.post(`/poll/question/${questionId}`, {
    content,
    isActive,
    isShared,
    showOnPreviewMonitor,
    outputShowWholeAnswer,
    showPercentage,
    allowMultiSelect,
  });
  return data;
};

export const createEventPollAnswer = async (question: string, content: string, sortIndex?: number) => {
  const { data: data } = await axios.post(`/poll/answer`, { question, content, sortIndex });
  return data;
};

export const importBaymeEvents = async (code: string) => {
  const { data: data } = await axios.post(`/integration/baymevbm/event/import`, { code });
  return data;
};

export const overwriteBaymePublications = async (code: string) => {
  const { data: data } = await axios.post(`/integration/baymevbm/event/publications`, { code });
  return data;
};

export const checkBaymeEventByCode = async (code: string) => {
  const { data: data } = await axios.get(`/integration/baymevbm/event/check/${code}`);
  return data;
};

export const getBaymeSurveyText = async (code: string) => {
  const { data: data } = await axios.get(`/integration/baymevbm/survey/${code}`);
  return data;
};

export const getAnswersPerQuestion = async (questionId: string) => {
  const { data: data }: AxiosResponse<{ status: string; data: EventPollAnswer[] }> = await axios.get(`/poll/answersperquestion/${questionId}`);
  return data.data;
};

export const updateEventPollAnswers = async (answers: Partial<EventPollAnswer>[], questionId: string) => {
  const { data: data } = await axios.post(`/poll/answers/update`, { answers, questionId });
  return data;
};

export const getQuestionForUser = async (questionId: string, userId?: string) => {
  const { data: data } = await axios.post(`/poll/questionforuser/${questionId}`, { userId });
  return data.data;
};

export const checkEventPollAnswer = async (answers: string[], event: string, pollId?: string) => {
  const { data: data } = await axios.post(`/poll/answer/reply`, { answers, event, pollId });
  return data;
};

export const checkEventPollAnswerUnregistered = async (answers: string[], event: string, pollId: string) => {
  const { data: data } = await axios.post(`/poll/answer/reply/unregistered`, { answers, event, pollId });
  return data;
};

export const getTotalAnswers = async (questionId: string) => {
  const { data: data } = await axios.get(`/poll/totalanswers/${questionId}`);
  return data.data;
};

export const getCheckedAnswersPerUser = async (answerId: string) => {
  const { data: data } = await axios.get(`/poll/answerchecked/${answerId}`);
  return data.data;
};

export const deleteAnswer = async (answerId: string) => {
  const { data: data } = await axios.delete(`/poll/answer/${answerId}`);
  return data;
};

export const getOwnAnsweredPollsByEvent = async (event: string) => {
  const { data: data } = await axios.get(`/poll/ownrepliesbyevent/${event}`);
  return data.data;
};

export const updateFilterCategories = async (boothids: string[], categoryfiltervalues: string[]) => {
  return await axios.post('/booth/update/categories', { boothids, categoryfiltervalues });
};

export type CreateEventProps = Omit<EventTemplate, 'datetime_created' | 'datetime_deleted' | '_id'>;

export const getAllEventTemplates = async (): Promise<EventTemplate[]> => {
  const { data } = await axios.get(`/eventtemplate/`);
  return data.data;
};

export const createEventTemplate = async (props: Partial<CreateEventProps>): Promise<EventTemplate> => {
  const { data } = await axios.post(`/eventtemplate/`, props);
  return data;
};

export const updateEventTemplate = async (id: string, props: Partial<CreateEventProps>): Promise<EventTemplate> => {
  const { data } = await axios.patch(`/eventtemplate/${id}`, props);
  return data;
};

export const deleteEventTemplate = async (id: string): Promise<EventTemplate> => {
  const { data } = await axios.delete(`/eventtemplate/${id}`);
  return data;
};

export const createEvent = async (name?: string): Promise<string> => {
  // returns an Id
  const { data } = await axios.post(`/event/create`, { name });
  return data.data;
};

interface CreateEventDetailProps extends Omit<Partial<EventInterface>, 'settings'> {
  name?: string;
  organization?: any;
  createDefaultPages?: boolean;
  settings: Partial<EventInterface['settings']>;
}
export const createEventDetail = async (props: CreateEventDetailProps): Promise<string> => {
  // returns an Id
  const { data } = await axios.post(`/event/create/detail`, props);
  return data.data;
};

interface DuplicateProps {
  eventid: string;
  name?: string;
  adjustOrganization?: boolean;
  amount?: number;
  excludeName?: boolean;
  scope?: {
    pages?: boolean;
    emailtemplates?: boolean;
    eventregistrationfields?: boolean;
    networking?: boolean;
    accessrules?: boolean;
    privileges?: boolean;
  };
}

export const duplicateEvent = async (props: DuplicateProps): Promise<string> => {
  const { data } = await axios.post(`/event/duplicate`, props);
  return data.data;
};

export const joinMeetingQueue = async (meetingroomid: string) => {
  const data = await axios.post('/meeting/queue/join', { meetingroomid });
  return data;
};

export const getMeetingQueue = async (meetingroomid: string) => {
  const { data: data } = await axios.get(`/meeting/queue/room/${meetingroomid}`);
  return data;
};

export const inviteUserFromQueue = async (queueid: string): Promise<void> => {
  await axios.post(`/meeting/queue/invite`, { queuepositionid: queueid });
};

export const updateQueueItem = async (queueid: string, hidden: boolean) => {
  const { data: data } = await axios.post(`/meeting/queue/item`, { queuepositionid: queueid, hidden: hidden });
  return data.data;
};

export const removeFromQueue = async (meetingroomid: string, userid: string) => {
  return await axios.post('meeting/queue/removeUser', { meetingroomid, userid });
};

export const getFadables = async (eventId: string) => {
  const { data: fadableElements } = await axios.get(`/event/director/fadable/${eventId}`);
  return fadableElements;
};

export const findPotentialMatchingUsers = async (eventId: string, registrationFilters?: RegistrationFieldFilter[]) => {
  const { data: data } = await axios.post(`/meeting/matching/find/${eventId}`, { registrationFilters });
  return data.data;
};

export interface VideoType {
  value: string;
  displayname: string;
}

export const getSupportedVideoTypes = async (): Promise<VideoType[]> => {
  const { data } = await axios.get('/event/supportedwelcomevideotypes');
  return data.data;
};

export const getOwnFavoriteAgendaEntries = async (eventid: string): Promise<FavoriteAgendaEntry[]> => {
  const { data } = await axios.get(`/agenda/favorite/own/${eventid}`);
  return data.data;
};

// agenda services

export const addAgendaEntry = async (props: AgendaEntry, component: string): Promise<AgendaEntry> => {
  const { data } = await axios.post(`/agenda/entry`, { ...props, component });
  return data.data;
};

export const deleteAgendaEntry = async (entryid: string): Promise<AgendaEntry> => {
  const { data } = await axios.delete(`/agenda/entry/${entryid}`);
  return data.data;
};

export const updateAgendaEntry = async (entryid: string, updateObject: AgendaEntry): Promise<AgendaEntry> => {
  const { data } = await axios.post(`/agenda/entry/update`, { ...updateObject, entryid });
  return data.data;
};

// favorite agenda entries services
interface FavoriteAgendaEntryProps {
  componentid: string;
  entryid: string;
  eventid: string;
}

export const addFavoriteAgendaEntry = async (props: FavoriteAgendaEntryProps): Promise<FavoriteAgendaEntry> => {
  const { data } = await axios.post(`/agenda/favorite`, props);
  return data.data;
};

export const deleteFavoriteAgendaEntry = async (entryid: string): Promise<FavoriteAgendaEntry> => {
  const { data } = await axios.delete(`/agenda/favorite/${entryid}`);
  return data.data;
};
export const createSpeaker = async (eventId: string, props: Partial<SpeakerEntry>) => {
  const { data } = await axios.post(`/speaker/`, { eventId, ...props });
  return data.data;
};

export const updateSpeaker = async (speakerId: string, props: Partial<SpeakerEntry>) => {
  const { data } = await axios.patch(`/speaker/${speakerId}`, props);
  return data.data;
};

export const deleteSpeaker = async (speakerId: string) => {
  const { data } = await axios.delete(`/speaker/${speakerId}`);
  return data.data;
};

export const getSpeakersForEvent = async (eventId: string) => {
  const { data } = await axios.get(`/speaker/${eventId}`);
  return data?.data;
};

export const getFavouriteBooths = async (eventid?: string) => {
  const { data: favouriteBooths }: AxiosResponse<{ status: string; data: FavouriteBooth[] }> = await axios.get(`/booth/favourite/own/${eventid}`);
  return favouriteBooths;
};

export const getBoothLikesCount = async (eventId?: string) => {
  const { data }: AxiosResponse<{ status: string; boothLikes: BoothLikesCount[] }> = await axios.get(`/booth/likescount/${eventId}`);
  return data.boothLikes;
};

export const addFavouriteBooth = async (boothid: string) => {
  const { data: favouriteBooth }: AxiosResponse<{ status: string; data: FavouriteBooth[] }> = await axios.post(`/booth/favourite/`, { boothid });
  return favouriteBooth;
};

export const deleteFavouriteBooth = async (favouriteId: string) => {
  return axios.delete(`/booth/favourite/${favouriteId}`);
};

export const updateCurrentActiveTab = async (registrationId: string, activeTabId: string) => {
  await axios.patch(`/attendee/currentactivetab/${registrationId}`, { activeTabId });
};

export const toggleChat = async (pageId?: string, meetingroomid?: string) => {
  return axios.post('/meeting/control/togglechat', { meetingroomid, pageId });
};

export const inviteMatchingUser = async (meetingid: string, userid: string, message?: string) => {
  const { data: data } = await axios.post(`/meeting/matching/invite/manual`, {
    meetingroomid: meetingid,
    userid: userid,
    message,
  });
  return data.data;
};

export const triggerOpenBoothTaskService = async (eventid: string, boothid: string) => {
  await axios.post('/booth/open', { eventid, boothid });
};

export const getMeetingIdOfTemporary = async (code: string) => {
  const { data: data } = await axios.get(`/meeting/temporary/${code.toUpperCase()}?token=`);
  return data.data.meetingid;
};

export const createTemporaryMeeting = async (token: string, useV3: boolean) => {
  const { data: data } = await axios.post(`/meeting/temporary/create`, {
    type: 'conference',
    captchatoken: token,
    useVonage: useV3,
  });
  return data.data.code;
};

export const getEventConnectedUsersCount = async (eventid?: string) => {
  const { data: usercount } = await axios.get(`/event/${eventid}/usercount`);
  return usercount.data;
};

export const sendChatLink = async (meetingroomid: string): Promise<any> => {
  const { data: data } = await axios.post('/meeting/control/sendchatlink', {
    meetingroomid,
  });

  return data;
};

export const getBoothAccessUsers = async (boothId: string): Promise<BoothAccessUserType[]> => {
  const { data: data } = await axios.get(`/userprivilege/booth/${boothId}`);

  return data.data;
};

export const getOrgUsersForBooth = async (boothId: string): Promise<BoothOrgUser[]> => {
  const { data } = await axios.get(`/organization/users/booth/${boothId}`);
  return data.data;
};

export const toggleLikeButton = async (meetingId: string) => {
  const { data } = await axios.post('/meeting/control/togglelikebutton', { meetingId });
  return data;
};

export const createNewOutputService = async (name: string, eventId: string): Promise<Output> => {
  const { data } = await axios.post('/output/', { name, eventId });
  return data.data;
};

export const getAllOutputs = async (eventId: string): Promise<Output[]> => {
  const { data } = await axios.get(`/output/event/${eventId}`);
  return data.data;
};

export const getOutput = async (outputId: string): Promise<OutputPopulated> => {
  const { data } = await axios.get(`/output/${outputId}`);
  return data.data;
};

export const deleteOutputService = async (outputId: string): Promise<Output> => {
  const { data } = await axios.delete(`/output/${outputId}`);
  return data.data;
};

export const updateOutputService = async (outputId: string, updateObject: Partial<Output>): Promise<Output> => {
  const { data } = await axios.patch(`/output/${outputId}`, { ...updateObject });
  return data.data;
};

export const getOutputPinService = async (outputId: string, pageId: string): Promise<string> => {
  const { data } = await axios.get(`/output/pin/${outputId}/${pageId}`);
  return data.data;
};

export const createOutputPinService = async (outputId: string, pageId?: string): Promise<Output> => {
  const { data } = await axios.post(`/output/pin`, { outputId, pageId });
  return data.data;
};

export const deleteOutputPinService = async (outputId: string, pageId?: string): Promise<Output> => {
  const { data } = await axios.post(`/output/pin/delete`, { outputId, pageId });
  return data.data;
};

export const verifyOutputPinService = async (pin: string): Promise<PageData> => {
  const { data } = await axios.post(`/output/pin/verify`, { pin });
  return data.data;
};

interface ExhibitorUserInformationProps {
  eventid: string;
  content: string;
  title: string;
  author?: string;
  category?: string;
  sendEmail?: boolean;
  attachments?: string[];
}

export const createExhibitorUserInformation = async (props: ExhibitorUserInformationProps): Promise<Output> => {
  return await axios.post('/userinformation/exhibitor/create', props);
};

export const sendTestExhibitorUserInformation = async (props: ExhibitorUserInformationProps): Promise<Output> => {
  return await axios.post('/userinformation/exhibitor/test', props);
};
export const getAllExhibitors = async (eventid: string): Promise<Exhibitor[]> => {
  const { data } = await axios.get(`/userinformation/exhibitor/getall/${eventid}`);
  return data.data;
};
export const deleteExhibitorInformation = async (id: string): Promise<void> => {
  await axios.delete(`/userinformation/exhibitor/${id}`);
};

export const registerTemporary = async (name: string, mail: string): Promise<{ status: string; data: User; access_token: string }> => {
  const { data }: AxiosResponse = await axios.post('attendee/register/temporary', {
    name: name,
    email: mail,
  });

  return data.data;
};

updateOutputService;

export const getQueueList = async (meetingroomid: string) => {
  const { data: data } = await axios.get(`/meeting/queue/room/${meetingroomid}`);
  return data.data;
};

export const getPagesSupportedTemplates = async (): Promise<PageTemplate[]> => {
  const { data: response } = await axios.get('event/pages/supportedtemplates');
  return response.data;
};

export const callToParent = async (meetingId: string, eventId: string) => {
  const { data: data } = await axios.post('/meeting/control/calltoparent', { meetingId, eventId });
  return data;
};

export interface EventRequest {
  datetimeStart: Date;
  datetimeEnd: Date;
  name: string;
}

export interface PresenceTimeFrameEvents {
  participated: boolean;
  email: string;
  minutes: number;
  pageName: string;
  eventName: string;
}

export const getPresenceAnalytics = async (pageIds: string[], events: EventRequest[]): Promise<PresenceTimeFrameEvents[]> => {
  const { data: data } = await axios.post(`/reporting/event/userpresencetimeframe`, {
    pageIds,
    events,
  });
  return data.data;
};

export const requestCall = async (calleeId: string, eventId: string): Promise<Meetingroom> => {
  const { data: data } = await axios.post('/call', {
    calleeId,
    eventId,
  });

  return data.data;
};

export const sendCallHandled = async (eventId: string): Promise<void> => {
  await axios.post('/call/handled', { eventId });
};

export const hangupCall = async (meetingId: string): Promise<void> => {
  await axios.post('/call/hangup', { meetingId });
};

export const cancelCall = async (calleeId: string, eventId: string): Promise<void> => {
  await axios.post('/call/cancel', {
    calleeId,
    eventId,
  });
};

export const postNote = async (datashareid: string, text: string): Promise<void> => {
  await axios.post('meeting/note/add', {
    datashareid: datashareid,
    note: text,
  });
};

export const getNotes = async (meetingId: string): Promise<ContactShare[]> => {
  const { data: notes }: AxiosResponse = await axios.post('meeting/note/getall', {
    meetingroomid: meetingId,
  });

  return notes.data;
};

export interface PopulatedBooth extends Booth {
  booth: Booth;
}

export const getUserPermissions = async (userId: string): Promise<PopulatedBooth[]> => {
  const { data: data }: AxiosResponse = await axios.post('userprivilege/user', {
    userId,
  });

  return data.data;
};

export const deleteNote = async (noteId: string): Promise<void> => {
  await axios.delete(`meeting/note/${noteId}`);
};

export const getMatchingSetup = async (eventId: string) => {
  const { data: data }: AxiosResponse<{ status: string; data: any }> = await axios.get(`/usermatching/setup/${eventId}`);
  return data.data;
};

export const endUserMatch = async (eventId: string) => {
  await axios.post(`/usermatching/end/${eventId}`);
};

export const clearChat = async (meetingroomid: string) => {
  return await axios.post('/meeting/chat/clear', { meetingroomid });
};

export const getAllMatches = async (eventId: string, offset = 0, limit = 25, searchEmail?: string) => {
  const { data: data }: AxiosResponse<{ status: string; data: Email[]; total: number }> = await axios.get(`/usermatching/allmatches/${eventId}`, {
    params: { offset, limit, searchEmail },
  });
  return data;
};

export const getLastMatchesCount = async (eventId: string) => {
  const { data: data }: AxiosResponse<{ status: string; data: { count: number } }> = await axios.get(`/usermatching/lastmatchescount/${eventId}`);
  return data;
};
export const highlightMessage = async (messageid: string) => {
  const { data } = await axios.post('/meeting/chat/highlight', { messageid });
  return data.data.chatmessage;
};

export const updateMatchingSetup = async (eventId: string, registrationFields: string[], minScore: number) => {
  const { data: status }: AxiosResponse<{ status: string }> = await axios.post(`/usermatching/setup/${eventId}`, {
    registrationFields,
    minScore,
  });
  return status;
};

export const resetMatchingSetup = async (eventId: string) => {
  const { data: status }: AxiosResponse<{ status: string }> = await axios.post(`/usermatching/resetsetup/${eventId}`);
  return status;
};

export const getMyUserMatchingsHistory = async (registrationId: string): Promise<UserMatch[]> => {
  const { data } = await axios.get(`/usermatching/mymatches/${registrationId}/history`);
  return data.data;
};

export const getMyUserMatchingsAll = async (registrationId: string): Promise<UserMatch[]> => {
  const { data } = await axios.get(`/usermatching/mymatches/${registrationId}/all`);
  return data.data;
};

export const toggleUserMatchHighlight = async (usermatchId: string, ownRegistrationId: string) => {
  const { data } = await axios.post('/usermatching/highlight', {
    usermatchId,
    ownRegistrationId,
  });
};

export const resendEmail = async (emailId: string) => {
  const { data } = await axios.post('/mail/sendagain', {
    mailid: emailId,
  });
};

interface AuthLoginProps {
  email: string;
  password: string;
  eventPasswordLoginId?: string;
}

interface AuthLoginResponse {
  access_token: string;
  user: User;
  role: Role;
}

export const authLogin = async (props: AuthLoginProps) => {
  const { data }: AxiosResponse<{ data: AuthLoginResponse }> = await axios.post('/auth/login', props);
  return data;
};

// Presentation ticket services
export const fetchPresentationTickets = async (registration: string) => {
  const { data }: AxiosResponse<{ data: PresentationTicket[] }> = await axios.get(`/presentationticket/getall/${registration}`);
  return data;
};

interface CreatePresentationTicketProps {
  event: string;
  slot: string;
  registration: string;
}

export const createPresentationTicket = async (props: CreatePresentationTicketProps) => {
  const { data }: AxiosResponse<{ data: PresentationTicket }> = await axios.post(`/presentationticket/create`, props);
  return data;
};

export const deletePresentationTicket = async (ticketId?: string) => {
  await axios.delete(`/presentationticket/${ticketId}`);
};

export const fetchPresentationSignedGuests = async (slot: string) => {
  const { data } = await axios.get(`/presentationticket/signedguests/${slot}`);
  return data.data;
};

export const forwardQueue = async (meetingroomid: string, userid: string, sourcemeeting: string) => {
  const { data: data } = await axios.post(`/meeting/queue/forward`, { meetingroomid, userid, sourcemeeting });
  return data.data;
};

export const getAllBoothsPerEvent = async (eventId: string, boothId: string) => {
  const { data } = await axios.get(`event/${eventId}/boothmeetings${boothId ? `?booth=${boothId}` : ``}`);
  return data;
};

export const getTicketOrderById = async (orderId: string, accessToken: string): Promise<TicketOrder> => {
  const accessConfig = { headers: { Authorization: accessToken } };

  const { data: data } = await plainAxios.get(`ticket/order/${orderId}`, accessConfig);
  return data.data;
};

export const getAllTicketOrdersForEvent = async (eventId: string, discountCode?: string) => {
  const { data: data } = await axios.get(`ticket/order/event/${eventId}${discountCode ? `/${discountCode}` : ''}`);
  return data.data as TicketOrder[];
};

export const getAllTicketTypesForEvent = async (eventId: string) => {
  const { data: data } = await axios.get(`ticket/type/event/${eventId}`);
  return data.data as TicketType[];
};
export const getAllDetailedTicketTypesForEvent = async (eventId: string) => {
  const { data: data } = await axios.get(`ticket/type/detailed/event/${eventId}`);
  return data.data as DetailedTicketType[];
};
export const getAllTicketTypesForEventSignup = async (eventId: string) => {
  const { data: data } = await axios.get(`ticket/type/event/signup/${eventId}`);
  return data.data as DetailedTicketType[];
};
export const getAllTicketDiscountCodesForEvent = async (eventId: string) => {
  const { data: data } = await axios.get(`ticket/discount/event/${eventId}`);
  return data.data as TicketDiscountCode[];
};
export const createTicketOrder = async (
  eventId: string,
  basket: { type?: string; amount: number }[],
  billingData: BillingDataInterface,
  discountCodes: string[] = [],
  addTax: boolean,
  orderId: string,
  isGroupOrder: boolean,
  mainTicketOrderNumber?: string | null
) => {
  const { data: data } = await axios.post(`ticket/order`, {
    basket,
    billingData,
    eventId,
    discountCodes,
    addTax,
    orderId,
    isGroupOrder,
    mainTicketOrderNumber,
  });
  return data.data;
};

export const reserveBasketService = async (eventId: string, basket: any[]) => {
  const { data: data } = await axios.post(`ticket/reserve`, {
    basket,
    eventId,
  });
  return { ...data.data, datetime_start: new Date(data.data.datetime_start) };
};

export const deleteBasketReservation = async (orderId: string) => {
  await axios.delete(`ticket/reserve/${orderId}`);
};

export const deleteTicketOrder = async (orderId: string) => {
  await axios.delete(`ticket/order/${orderId}`);
};

export const payOrderManually = async (orderId: string) => {
  await axios.patch(`ticket/order/pay/manually/${orderId}`);
};

export const createTicketType = async (
  eventId: string,
  name: string,
  paymentType: SupportedTicketPaymentTypes,
  sortIndex: number,
  price?: number,
  currency?: SupportedTicketPaymentCurrencies,
  minPerOrder?: number,
  maxPerOrder?: number,
  amountAvailable?: number | null,
  description?: string,
  datetime_available?: Date,
  datetime_expiry?: Date,
  allowTimeRestriction?: boolean,
  registrationEmailTemplate?: string | null,
  priceType?: string,
  teaser?: string
) => {
  const { data } = await axios.post(`ticket/type/`, {
    eventId,
    name,
    paymentType,
    price,
    description,
    currency,
    minPerOrder,
    maxPerOrder,
    amountAvailable,
    datetime_available,
    datetime_expiry,
    allowTimeRestriction,
    sortIndex,
    registrationEmailTemplate,
    priceType,
    teaser,
  });
  return data;
};

export const updateTicketType = async (typeId: string, updateProps: Partial<TicketType> & { isHidden?: boolean; priceType?: string }) => {
  const { data } = await axios.patch(`ticket/type/${typeId}`, updateProps);
  return data;
};
export const deleteTicketType = async (ticketId: string) => {
  await axios.delete(`/ticket/type/${ticketId}`);
};

export const sortTicketType = async (typeId: string, targetId: string) => {
  await axios.post(`/ticket/type/sort/${typeId}`, { targetId });
};

export const createTicketDiscountCode = async (
  eventId: string,
  code: string,
  type: 'limited' | 'unlimited',
  percentage: number,
  limit?: number,
  ticketTypeIds?: string[]
) => {
  const { data } = await axios.post(`ticket/discount/`, {
    eventId,
    code,
    type,
    percentage,
    limit,
    ticketTypeIds,
  });
  return data;
};

export const updateTicketDiscountCode = async (typeId: string, updateProps: Partial<TicketDiscountCode>) => {
  const { data } = await axios.patch(`ticket/discount/${typeId}`, updateProps);
  return data;
};

export const deleteTicketDiscountCode = async (discountId: string) => {
  await axios.delete(`/ticket/discount/${discountId}`);
};

export const checkTicketDiscountCodePercentage = async (code: string, eventid: string) => {
  const { data }: AxiosResponse<{ data: TicketDiscountCode }> = await axios.post(`/ticket/discount/check`, {
    code,
    eventid,
  });
  return data.data;
};
export const createPayoutInformation = async (payoutInformation: PayoutInformation) => {
  await axios.post(`/ticket/payoutinfo/${payoutInformation.event}`, payoutInformation);
};

export const updatePayoutInformation = async (payoutInformation: PayoutInformation) => {
  await axios.patch(`/ticket/payoutinfo/${payoutInformation.event}/${payoutInformation._id!}`, payoutInformation);
};

export const requestPayout = async (eventId: string, amount: number) => {
  await axios.post('/ticket/requestpayout', { eventId, amount });
};

export const getPayoutInfo = async (eventId: string) => {
  const { data }: AxiosResponse<{ data: PayoutInformation }> = await axios.get(`/ticket/payoutinfo/${eventId}`);
  return data;
};

export const getBillingInfos = async () => {
  const { data }: AxiosResponse<{ data: BillingInfos[] }> = await axios.get(`/reporting/billinginfos`);
  return data.data;
};

export const generateTicketInvoice = async (orderId: string) => {
  const { data } = await axios.post(`ticket/order/${orderId}/invoice`);
  return data;
};

export const generateCreditNote = async (orderId: string) => {
  const { data } = await axios.post(`ticket/order/${orderId}/creditnote`);
  return data;
};

export const getControllingSummary = async (month?: number) => {
  const { data }: AxiosResponse<{ data: ControllingEntry[] }> = await axios.get(`/reporting/billinginfos/summary`, { params: { month: month } });
  return data.data;
};

export const getAllFilterCategories = async (eventId: string, onlyPublic?: boolean) => {
  const { data }: AxiosResponse<{ data: AdvancedFilter[] }> = await axios.get(
    `filtercategory/getall/${eventId}?${onlyPublic !== false ? `uservisible=true` : ``}`
  );
  return data.data;
};

export const toggleBreakoutVisible = async (meetingId: string) => {
  await axios.patch(`/meeting/control/togglebreakout/${meetingId}`);
};

export const getChangelogs = async (eventId: string) => {
  const { data }: AxiosResponse<{ data: any }> = await axios.get(`/event/changelogs/${eventId}`);
  return data.data;
};

export const cutMedia = async (mediaId: string, start: number, end: number) => {
  await axios.post(`/media/cut`, {
    mediaId,
    start,
    end,
  });
};

export const unsubscribeGuestcommunication = async (registrationId: string, email: string) => {
  await axios.post(`/attendee/unsubscribe`, { registrationId, email });
};

export const getRegistrationRealtimeEvents = async (registrationId: string) => {
  const {
    data,
  }: AxiosResponse<{
    data: { trackingEventsMeeting: TrackingEventPopulated[]; connectEvents: TrackingEventPopulated[]; messages: MeetingChatMessage[] };
  }> = await axios.get(`/attendee/registration/realtime/${registrationId}`);
  return data.data;
};

export const getUserRelatedDetails = async (userId: string) => {
  const { data }: AxiosResponse<{ data: any }> = await axios.get(`/attendee/relateddetails/${userId}`);
  return data.data;
};

export const getUserBanAndReportHistory = async (eventId: string, userId: string) => {
  const { data }: AxiosResponse<{ data: { reports: any; bans: UserBan[] } }> = await axios.post('/userreport/history', { userId, eventId });
  return data.data;
};

export const banUser = async (eventId: string, banType: string, userId: string) => {
  const { data }: AxiosResponse<{ data: any }> = await axios.post('/userreport/ban', { eventid: eventId, bantype: banType, userid: userId });
  return data.data;
};

export const getMeetingDetails = async (meetingId: string) => {
  const { data: details }: AxiosResponse<{ data: MeetingDetails }> = await axios.get(`/reporting/meetingdetails/${meetingId}`);
  return details.data;
};

export const getMeetingActivities = async (meetingId: string) => {
  const { data: activities }: AxiosResponse<{ data: MeetingActivities }> = await axios.get(`/reporting/meetingactivities/${meetingId}`);
  return activities.data;
};

export const getMeetingRecordings = async (meetingId: string) => {
  const { data: details }: AxiosResponse<{ data: media[] }> = await axios.get(`/media/meeting/${meetingId}/recordings`);
  return details.data;
};

export const getEventMeetings = async (eventId: string) => {
  const { data }: AxiosResponse<{ data: Meetingroom[] }> = await axios.get(`/meeting/event/all/${eventId}`);
  return data.data;
};

export const changeRegistrationStatus = async (registrationid: string, status: string) => {
  const data = await axios.post('/attendee/status', { registrationid, status });
  return data.data;
};

export const getEventauth = async (provider: string, providertoken: string) => {
  const { data: data } = await axios.post('/attendee/eventauth ', { provider, providertoken });
  return data.data;
};

export const signup = async (props: { email: string; name: string }) => {
  const { data: data } = await axios.post('/auth/signup', props);
  return data.data;
};

export const requestUpgrade = async (additionalSettingCheck?: string) => {
  const { data: data } = await axios.post('/subscription/upgrade/request', { additionalSettingCheck });
  return data.data;
};

export const orderPremiumService = async (user: string, organization: string, event: string, order: any) => {
  const { data: data } = await axios.post('/subscription/upgrade/order', { user, organization, event, order });
  return data.data;
};

export const updatePremiumServiceOrderStatus = async (orderId: string, updateObj: unknown) => {
  await axios.patch(`/subscription/upgrade/order/${orderId}`, updateObj);
};

export const getActiveOrders = async (organization: string) => {
  const { data: data } = await axios.get(`/subscription/upgrade/orders/${organization}`);
  return data.data as PremiumServiceOrder[] | [];
};

export const generatePdfThumbnail = async (fileId: string) => {
  const { data: data } = await axios.post('/file/pdfthumbnail', { fileId });
  return data.data;
};

export const getEventTotalParticipants = async (eventId: string) => {
  const { data: data } = await axios.get(`/attendee/count/${eventId}`);
  return data.data.count;
};

export const exportUserPresence = async (pageId: string) => {
  const { data: data } = await axios.get(`/reporting/event/userpresenceagenda/${pageId}`);
  return data.data;
};

export const exportFullUserPresence = async (eventId: string) => {
  const { data: data } = await axios.get(`/reporting/event/userpresence/${eventId}`);
  return data.data;
};

export const isExceedingOrgBudget = async (organizationId: string, budgetType: string) => {
  await axios.get(`/sales/platformusage/type/${budgetType}/${organizationId}`);
};

export const updateEventMailTemplate = async (eventId: string, data: any) => {
  await axios.post(`/emailtemplate/event/template/${eventId}`, data);
};

export const fetchMailTemplateBaseData = async (eventId: string, templateValue: string, language?: string) => {
  const { data: data } = await axios.get(
    `emailtemplate/preview/${templateValue}?eventid=${eventId}&onlyRenderData=true&skipCustomTemplate=true&language=${
      !language || language === 'all' ? 'de' : language
    }`
  );

  return data;
};

export const verifyMainTicketHolder = async (provider: string, providerToken: string) => {
  const { data: data } = await axios.post(`/attendee/verifymainticket`, { provider, providerToken });
  return data.data as { isMainGroupTicketHolder: boolean; orderNumber: string };
};

export const checkoutAllGuests = async (eventId: string) => {
  await axios.post('/hybrid/check/out/all', { eventid: eventId });
};

export const checkPresentationTicket = async (slotId: string, registrationId: string) => {
  const { data: data } = await axios.post('/presentationticket/checksignedup', {
    slot: slotId,
    registration: registrationId,
  });

  return data;
};

export const getRegistrationData = async (provider: string, providerToken: string) => {
  const { data: data } = await axios.get(`/hybrid/check/data?providertoken=${providerToken}&provider=${provider}`);

  return data;
};

export const getFileInfo = async (fileId: string) => {
  const { data: data } = await axios.get(`/file/getinfo/${fileId}`);
  return data;
};

export const getAllOrgMemberships = async (organizationId: string) => {
  const { data }: AxiosResponse<{ data: OrgMembership[] }> = await axios.get(`/organization/memberships/${organizationId}`);
  return data;
};
